import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEqual, isEmpty, uniq } from 'lodash';
import { useOrderHistoryGroupingQuery } from 'api/myOrders';
import {
	OrderHistoryViewportSize,
	useOrderHistoryViewportSize,
} from 'components/shared/hooks/useOrderHistoryViewportSize';
import {
	Area,
	FilterRequest,
	GroupingResponse,
	OrderHistoryGroupingRequest,
	OrderHistorySearchGroup,
	OrderHistorySearchGroupingType,
	OrderHistorySort,
	PageType,
	SortDirection,
	SortingResponseOrderHistorySort,
} from 'generated/data-contracts';
import { OrderListUrlParamKeys, PREDEFINED_ORDERLIST_URL_PARAMS } from '../constants/OrderListUrlParamKeys';
import { useAccountsFilter } from '../hooks/useAccountsFilter';
import { UseFilterActionsResponse, useFilterActions } from '../hooks/useFilterActions';
import { useGroupingFilter } from '../hooks/useGroupingFilter';


type OrderHistoryLayoutInfo = {
	totalValue?: string;
	totalQuantity?: number;
};

export type UseOrdersPageContextResponse = {
	shipToGroupings: GroupingResponse[];
	status: OrderHistorySearchGroup;
	layoutInfo: OrderHistoryLayoutInfo;
	isLoading: boolean;
	size: OrderHistoryViewportSize;
	changeSort: (sort: OrderHistorySort, sortDirection: SortDirection) => void;
	groupingOptions: {
		displayName: string;
		value: OrderHistorySearchGroupingType;
	}[];
	currentGrouping: OrderHistorySearchGroupingType;
	changeGrouping: (grouping: OrderHistorySearchGroupingType) => void;
	filters?: UseFilterActionsResponse;
	currentSort?: SortingResponseOrderHistorySort;
	sortOptions?: SortingResponseOrderHistorySort[];
	selectedShipTos: string[] | undefined;
};

const EXCLUDED_FROM_ACTIVE: string[] = [OrderListUrlParamKeys.ShipTo];
const FILTERED_SEARCH_PARAM_KEYS = PREDEFINED_ORDERLIST_URL_PARAMS.filter((r) => !EXCLUDED_FROM_ACTIVE.includes(r));
export const parseQueryParameters = (
	searchParams: URLSearchParams,
	selectedFilters: FilterRequest[] = [],
	selectedShipTos?: string[],
): OrderHistoryGroupingRequest => {
	const filters = selectedFilters.filter((filter) => {
		if (!filter.filter) return true;
		return !PREDEFINED_ORDERLIST_URL_PARAMS.includes(filter.filter);
	});
	return {
		phrase: searchParams.get(OrderListUrlParamKeys.SearchPhrase) || '',
		groupingType: OrderHistorySearchGroupingType.ShipTo,
		collapseGroups: [],
		status: (searchParams.get(OrderListUrlParamKeys.Tab) as OrderHistorySearchGroup) ?? OrderHistorySearchGroup.All,
		shipToIds: selectedShipTos?.length ? selectedShipTos : undefined,
		filters,
	};
};

export const useOrdersPageState = (): UseOrdersPageContextResponse => {
	const size = useOrderHistoryViewportSize();
	const [selectedShipTos, setSelectedShipTos] = useState<string[] | undefined>();
	const [searchParams, setSearchParams] = useSearchParams();
	const changeSort = (sort: OrderHistorySort, sortDirection: SortDirection): void => {
		setSearchParams(
			(prev) => {
				prev.set(OrderListUrlParamKeys.OrderlineSortBy, sort);
				prev.set(OrderListUrlParamKeys.OrderlineSortDirection, sortDirection);
				return prev;
			},
			{
				replace: true,
				state: { area: Area.StaticPages, pageType: PageType.Orders },
			},
		);
	};

	const [searchQuery, setSearchQuery] = React.useState<OrderHistoryGroupingRequest>();

	const { data: shipToResponse, isLoading } = useOrderHistoryGroupingQuery(searchQuery);

	const accountsFilter = useAccountsFilter(selectedShipTos);
	const filtersFromResponse = React.useMemo(() => {
		return [accountsFilter, ...(shipToResponse?.filters ?? [])];
	}, [accountsFilter, shipToResponse?.filters]);

	const filters = useFilterActions(filtersFromResponse, FILTERED_SEARCH_PARAM_KEYS, EXCLUDED_FROM_ACTIVE);

	useEffect(() => {
		setSearchQuery((prev) => {
			const newQuery = parseQueryParameters(searchParams, filters?.selectedFilters, selectedShipTos);
			if (!isEqual(prev, newQuery)) return newQuery;
			return prev;
		});
	}, [filters?.selectedFilters, searchParams, selectedShipTos]);

	useEffect(() => {
		const selectedShipTos = filters?.selectedFilters
			.filter(
				(filter): filter is { filter: string; value: string } =>
					filter.filter === OrderListUrlParamKeys.ShipTo && !isEmpty(filter.value),
			)
			.map((filter) => filter.value);
		if (selectedShipTos) {
			setSelectedShipTos(uniq(selectedShipTos));
		}
	}, [filters?.selectedFilters, setSelectedShipTos]);

	const sortOptions: SortingResponseOrderHistorySort[] = shipToResponse?.sorting ?? [];
	const currentSort = sortOptions.find(
		(sort) =>
			sort.sortBy === searchParams.get(OrderListUrlParamKeys.OrderlineSortBy) &&
			sort.sortDirection === searchParams.get(OrderListUrlParamKeys.OrderlineSortDirection),
	);
	const { changeGrouping, groupingOptions, currentGrouping } = useGroupingFilter();
	return {
		shipToGroupings: shipToResponse?.groupings ?? [],
		layoutInfo: {
			totalValue: shipToResponse?.totalValue ?? undefined,
			totalQuantity: shipToResponse?.totalQuantity,
		},
		status: (searchParams.get(OrderListUrlParamKeys.Tab) as OrderHistorySearchGroup) ?? OrderHistorySearchGroup.All,
		isLoading,
		selectedShipTos,
		filters,
		size,
		changeGrouping,
		groupingOptions,
		currentGrouping,
		changeSort,
		currentSort,
		sortOptions,
	};
};

const OrdersPageContext = React.createContext<UseOrdersPageContextResponse | null>(null);

export const OrdersPageContextProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
	const ordersActions = useOrdersPageState();
	return <OrdersPageContext.Provider value={ordersActions}>{children}</OrdersPageContext.Provider>;
};

export const useOrdersPageContext = (): UseOrdersPageContextResponse => {
	const context = useContext(OrdersPageContext);

	if (!context) {
		throw new Error('useOrdersActionsContext must be used within a OrdersActionsContextProvider');
	}

	return context;
};