import React, { ChangeEvent, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { InputCheckbox } from 'components/shared/InputCheckbox';
import NoResults from 'components/shared/NoResults';
import { SearchBar } from 'components/shared/SearchBar';
import { FilterResponse, FilterValueResponse } from 'generated/data-contracts';
import useInfiniteScroll from 'helpers/useInfiniteScroll';
import { useViewportSize } from 'helpers/useViewportSize';
import styles from './CheckboxFilter.module.scss';


interface CheckboxFilterProps extends FilterResponse {
	checkboxClassName?: string;
	onCheckboxChange?: (e) => void;
	fullScreen?: boolean;
	uniqueFilterId?: string;

	// Filter values
	search?: {
		searchText: string;
		setSearchText: (searchText: string) => void;
		clearSearchText: () => void;
	};
	hasNextPage?: boolean;
	loadMore?: () => void;
}

export const CheckboxFilter: React.FunctionComponent<CheckboxFilterProps> = ({
	name,
	values,
	checkboxClassName,
	onCheckboxChange,
	uniqueFilterId,
	fullScreen,
	hasNextPage,
	loadMore,
	search,
}) => {
	const [filteredValues, setFilteredValues] = React.useState<FilterValueResponse[]>(values || []);
	const { data: translations } = useTranslationQuery();
	const { isMobile } = useViewportSize();

	// Reset search & filteredValues, when values change
	React.useEffect(() => {
		if (!values) return;
		setFilteredValues(values);
	}, [values]);

	const renderLabel = (item: FilterValueResponse): React.ReactNode => (
		<div className={styles.checkboxLabel}>
			<span>{item.label}</span>
			{(item.hitCount ?? 0) > 0 && <span className={styles.hitCount}>({item.hitCount})</span>}
		</div>
	);

	const infinityScrollRef = useInfiniteScroll<HTMLDivElement>(() => {
		if (!loadMore || !hasNextPage) return;
		loadMore();
	});

	const onItemChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>): void => {
			if (onCheckboxChange) {
				onCheckboxChange(e);
			}
		},
		[onCheckboxChange],
	);

	const searchWithNoResults: boolean = (search?.searchText.length ?? 0) > 0 && filteredValues.length === 0;

	return (
		<div
			className={classNames(styles.scrollWrapper, 'u-scroll-wrapper-y', {
				[styles.isFullScreen]: fullScreen,
			})}
		>
			{search && (
				<SearchBar
					className={styles.searchBar}
					value={search.searchText}
					onChange={(e): void => search.setSearchText(e.target.value)}
					onClear={search.clearSearchText}
				/>
			)}
			<div
				className={classNames(styles.innerWrapper, {
					[styles.desktopLimitHeight]: !isMobile,
				})}
			>
				{searchWithNoResults && (
					<NoResults
						searchText={search?.searchText ?? ''}
						noResultsMessage={translations?.accountSelector.noAccountsForSearch ?? 'No results found'}
					/>
				)}
				{filteredValues.length > 0 &&
					filteredValues.map((item) => (
						<InputCheckbox
							className={checkboxClassName}
							label={renderLabel(item)}
							id={name + item.value + uniqueFilterId}
							name={item.value || ''}
							checked={item.isSelected || false}
							onChange={onItemChange}
							key={name + item.value + uniqueFilterId}
						/>
					))}

				<div ref={infinityScrollRef} />
			</div>
		</div>
	);
};