import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { messageToApp, formatTranslation, useDebounce, AppWebViews } from 'helpers';
import { isEmpty } from 'lodash';
import { BasketFilters, useBasketListQuery, useDeleteBasketsMutation, useSelectBasketMutation } from 'api/basket';
import { useShiptoSelectedListQuery } from 'api/shipTo';
import { useTranslationQuery } from 'api/translations';
import { SearchBar } from 'components/shared';
import { AlertTypes } from 'components/shared/Alert';
import { Button } from 'components/shared/Button';
import { ConfirmationAnswer, useConfirmDialog } from 'components/shared/ConfirmDialog';
import { DrawerBody, DrawerFooter, DrawerHeaderWBackButton, DrawerLevel } from 'components/shared/Drawer';
import { Icon } from 'components/shared/Icon';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { BasketListItemResponse, CreateOrderResponse } from 'generated/data-contracts';
import { trackDeleteBasket } from 'helpers/tracking';
import { basketActions } from 'store/actions/basketActions';
import { InitialState } from 'store/types';
import styles from './BasketSelector.module.scss';
import { BasketSelectorCard, BasketSelectorCardDataProps } from './BasketSelectorCard';
import CreateView from './CreateView';
import { DeleteBasketConfirmDialog } from './DeleteBasketConfirmDialog';
import EditView from './EditView';
import FilterView from './FilterView';
import { NoBaskets } from './NoBaskets';
import { OrderSummary } from './OrderSummary';

export interface BasketSelectorProps {
	onClose: () => void;
	children?: React.ReactNode;
	isBasket?: boolean;
	orderReference?: CreateOrderResponse;
	productId?: string;
	editMode?: boolean;
	createMode?: boolean;
	subLevelHandleBack?: () => void;
	hasNoCreateView?: boolean;
	shipTos?: string[];
}

type CurrentLocation = {
	state: {
		productFamilyId: string;
	};
};

const mapBasketItemsToDrawerCards = (
	baskets: BasketListItemResponse[] | undefined,
): BasketSelectorCardDataProps[] | undefined => {
	if (baskets === undefined) return;

	return baskets.map(
		(item: BasketListItemResponse): BasketSelectorCardDataProps => ({
			id: item.basketId,
			date: item.createdAt,
			isActive: item.isSelected,
			title: item.name || '',
			comment: item.salesComment || '',
			quantity: item.totalQuantity,
			shipTos: item.shipTos?.shipTos,
			otherShipTosCount: item.shipTos?.otherShipTosCount,
			creatorName: item.creatorName || '',
			brands: item.brands,
		}),
	);
};

export const BasketSelector: React.FunctionComponent<BasketSelectorProps> = ({
	onClose,
	subLevelHandleBack,
	isBasket,
	orderReference,
	editMode,
	createMode,
	productId,
	hasNoCreateView,
	shipTos,
}) => {
	const { data: selectedShipTos } = useShiptoSelectedListQuery();
	const defaultShipToIds: string[] | undefined = selectedShipTos?.shipTos.map((shipTo) => shipTo.id);

	const location = useLocation();
	const familyId = (location.state as Record<string, string>)?.productFamilyId || '';

	const [activeFilters, setActiveFilters] = useState<BasketFilters>({ ShipToIds: shipTos ?? defaultShipToIds });
	const [searchPhrase, setSearchPhrase] = useState('');

	const { data: translations } = useTranslationQuery();
	const { data: basketList, isFetching, isError } = useBasketListQuery(activeFilters, editMode);
	const selectBasket = useSelectBasketMutation();
	const deleteBaskets = useDeleteBasketsMutation();

	const isOrderSummary = (isBasket && !isEmpty(orderReference)) ?? false;

	const dispatch = useDispatch();
	const { notificationActions } = useNotificationContext();

	const { baskets, filters } = { ...basketList };
	const [mappedBasketList, setMappedBasketList] = useState<BasketSelectorCardDataProps[] | undefined>(
		mapBasketItemsToDrawerCards(baskets),
	);
	const [createView, setCreateView] = useState(createMode);
	const [editView, setEditView] = useState(editMode);
	const [filterView, setFilterView] = useState(false);
	const [deleteMode, setDeleteMode] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [selectLoadingId, setSelectLoadingId] = useState<number | null>(null);
	const [checkedBasketIds, setCheckedBasketIds] = useState<number[]>([]);

	const { pdpBasket } = useSelector((state: InitialState) => state.basket);
	const currentLocation: CurrentLocation = useLocation();
	const queryClient = useQueryClient();

	const deleteTranslation = !checkedBasketIds.length
		? translations?.openBaskets.edit.delete
		: checkedBasketIds.length === 1
		? translations?.openBaskets.edit.deleteBasket
		: formatTranslation(translations?.openBaskets.edit.deleteBaskets, {
				0: checkedBasketIds.length.toString(),
		  });

	const noResults = !isFetching && !isError && !isEmpty(searchPhrase.trim());

	useEffect(() => {
		setSearchPhrase(activeFilters.SearchPhrase ?? '');
	}, [activeFilters]);

	useEffect(() => {
		if (baskets === undefined) return;

		const drawerCardList = mapBasketItemsToDrawerCards(baskets);
		setMappedBasketList(drawerCardList);
	}, [baskets]);

	const toggleCreateView = (): void => {
		setCreateView((prevCreateView) => !prevCreateView);
	};

	const handleClose = React.useCallback((): void => {
		// only toggle/reset createView if true to fix calling accountSelectorBody unnecessary
		if (createView) {
			toggleCreateView();
		}

		onClose();
	}, [createView, onClose]);

	const toggleEditView = (): void => {
		setEditView((prevEditView) => !prevEditView);
	};

	const toggleFilterView = (): void => {
		setFilterView(!filterView);
	};

	const toggleDeleteMode = (): void => {
		setCheckedBasketIds([]);
		setDeleteMode((prevDeleteMode) => !prevDeleteMode);
	};

	const handleSelect = (event: React.FormEvent<HTMLButtonElement>, title): void => {
		const { value } = event.currentTarget;
		const valueAsNumber = Number(value);

		setSelectLoadingId(valueAsNumber);

		selectBasket.mutate(
			{ basketId: valueAsNumber, productFamilyId: currentLocation.state?.productFamilyId || '' },
			{
				onSuccess: (basketResponse) => {
					queryClient.invalidateQueries();

					const updatedBasketContent = { ...pdpBasket };
					if (!isEmpty(basketResponse.data.pdpBasketContent)) {
						updatedBasketContent.basketContents = basketResponse.data.pdpBasketContent;
					}

					const fallbackDate = basketResponse.data.basketResponse.basketContents.shipmentFilters[0];
					const activeDeliveryDate =
						basketResponse.data.basketResponse.basketContents.shipmentFilters.find(
							(date) => date.isSelected,
						) || fallbackDate;

					dispatch(basketActions.updatePDPBasket(updatedBasketContent));
					dispatch(
						basketActions.updatePreBasket({
							familyId: Object.keys(basketResponse.data.basketResponse.basketContents.families)[0],
							basketLines: [],
							basketDeliveryDate: activeDeliveryDate?.value || '',
						}),
					);

					let translation = translations?.openBaskets.namelessBasketUpdatedSuccess;
					if (title) {
						translation = formatTranslation(translations?.openBaskets.basketUpdatedSuccess, {
							0: title,
						});
					}

					messageToApp({ type: 'basketChanged' });

					if (isOrderSummary) {
						messageToApp({ type: 'redirectToTabAndOpenUrl', tabId: AppWebViews.SHOP, url: '/' });
					}

					notificationActions.addNotification({
						children: translation,
						type: AlertTypes.SUCCESS,
					});

					handleClose();
				},
				onSettled: () => {
					setSelectLoadingId(null);
				},
			},
		);
	};

	const handleChecked = (event: React.FormEvent<HTMLInputElement>): void => {
		const { checked, value } = event.currentTarget;
		const valueAsNumber = Number(value);

		let basketIDs;

		if (checked) {
			basketIDs = [...checkedBasketIds, valueAsNumber];
		} else {
			basketIDs = checkedBasketIds.filter((item) => item !== valueAsNumber);
		}

		setCheckedBasketIds(basketIDs);
	};

	const confirmDialog = useConfirmDialog(DeleteBasketConfirmDialog, { size: 'sm' });
	const handleDelete = (): void => {
		// Open confirm dialog
		confirmDialog.waitForAnswer().then((answer) => {
			if (ConfirmationAnswer.Rejected === answer) return;

			setDeleteLoading(true);

			deleteBaskets.mutate(
				{ query: { data: checkedBasketIds } },
				{
					onSuccess: () => {
						toggleDeleteMode();
						trackDeleteBasket(checkedBasketIds);
						notificationActions.addNotification({
							children: translations?.openBaskets.delete.basketDeletedSuccessfully,
							type: AlertTypes.SUCCESS,
						});
					},
					onSettled: () => {
						setDeleteLoading(false);
					},
				},
			);
		});
	};

	const getResults = useDebounce((inputtedValue: string) => {
		const newActiveFilters = { ...activeFilters };
		newActiveFilters.SearchPhrase = inputtedValue;
		setActiveFilters(newActiveFilters);
	}, 300);

	const handleSearchValueChange = (event): void => {
		const input: string = event.target.value;
		setSearchPhrase(input);
		getResults(input);
	};

	const handleSearchValueClear = (): void => {
		setSearchPhrase('');
		getResults('');
	};

	const handleBackCreateView = subLevelHandleBack ? subLevelHandleBack : toggleCreateView;
	const handleBackFilterView = subLevelHandleBack ? subLevelHandleBack : toggleFilterView;
	const handleBackEditView = subLevelHandleBack ? subLevelHandleBack : toggleEditView;

	return (
		<>
			<DrawerBody className={styles.basketSelectorBody}>
				{!orderReference && (
					<div className={styles.basketHeader}>
						<div className={styles.titleContainer}>
							<span className={styles.title}>{`${translations?.openBaskets.openBasketsTitle} (${
								baskets?.length ?? 0
							})`}</span>
							{!!baskets?.length && (
								<Button className={styles.edit} hasNoStyles onClick={toggleDeleteMode}>
									{!deleteMode && <Icon name={'edit'} size="sm" />}
									<span className={styles.editText}>
										{!deleteMode
											? translations?.openBaskets.edit.editList
											: translations?.shared.cancel}
									</span>
								</Button>
							)}
						</div>

						<div className={styles.searchAndFilters}>
							<SearchBar
								placeholder={translations?.openBaskets.searchPlaceholder}
								value={searchPhrase}
								onChange={handleSearchValueChange}
								onClear={handleSearchValueClear}
							/>
							{!!baskets?.length && (
								<div className={styles.filtersContainer}>
									<Button
										className={styles.filterButton}
										size={'sm'}
										variant={'outline'}
										onClick={toggleFilterView}
									>
										<Icon name={'filters'} size={'md'} />
										<span>{translations?.productList.filters.filtersButton}</span>
									</Button>
								</div>
							)}
						</div>
					</div>
				)}
				{isOrderSummary && <OrderSummary orderReference={orderReference} showTitle showCompletedBadge />}
				{isOrderSummary && (
					<div className={styles.orderConfirmationTitle}>
						{translations?.openBaskets.orderConfirmationTitle}
					</div>
				)}
				<ul className={styles.list}>
					{mappedBasketList?.length ? (
						mappedBasketList.map((item) => (
							<li key={item.id}>
								<BasketSelectorCard
									data={item}
									{...(!deleteMode && { onClick: (e): void => handleSelect(e, item.title) })}
									onChange={handleChecked}
									disabled={Boolean(selectLoadingId)}
									isLoading={selectLoadingId === item.id}
									isInput={deleteMode}
								/>
							</li>
						))
					) : (
						<li>
							<NoBaskets noResults={noResults} isLoading={isFetching} searchPhrase={searchPhrase} />
						</li>
					)}
				</ul>
			</DrawerBody>
			{confirmDialog.dialog}

			<DrawerFooter isSticky className={styles.basketDeleteActions}>
				{deleteMode ? (
					<>
						<Button
							variant="dark"
							disabled={!checkedBasketIds.length}
							isLoading={deleteLoading}
							onClick={handleDelete}
						>
							{deleteTranslation}
						</Button>

						<Button variant="secondary" onClick={toggleDeleteMode}>
							{translations?.shared.cancel}
						</Button>
					</>
				) : (
					!hasNoCreateView && (
						<Button variant="dark" onClick={isOrderSummary ? toggleEditView : toggleCreateView}>
							{translations?.openBaskets.create.new}
						</Button>
					)
				)}
			</DrawerFooter>

			{createView && (
				<DrawerLevel isActive={createView}>
					<DrawerHeaderWBackButton
						title={translations?.openBaskets.create.title}
						handleBack={handleBackCreateView}
						handleClose={handleClose}
					/>

					<CreateView productId={familyId} handleBack={handleBackCreateView} handleClose={toggleCreateView} />
				</DrawerLevel>
			)}
			{editView && (
				<DrawerLevel isActive={editView}>
					<DrawerHeaderWBackButton
						title={
							isOrderSummary
								? translations?.openBaskets.create.title
								: translations?.openBaskets.edit.title
						}
						handleClose={handleClose}
						{...(!editMode && { handleBack: handleBackEditView })}
					/>

					<EditView noPreselectedAccounts={isOrderSummary} handleClose={handleClose} productId={productId} />
				</DrawerLevel>
			)}
			{filterView && (
				<DrawerLevel isActive={filterView}>
					<DrawerHeaderWBackButton
						title={translations?.openBaskets.filters.allFilters}
						handleBack={handleBackFilterView}
						handleClose={handleClose}
					/>
					<FilterView
						handleBack={handleBackFilterView}
						handleClose={handleClose}
						filters={filters}
						activeFilters={activeFilters}
						setActiveFilters={setActiveFilters}
					/>
				</DrawerLevel>
			)}
		</>
	);
};
