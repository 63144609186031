import React, { FC, useRef, useState } from 'react';
import { createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useCommitProductBasketMutation, useProductBasketQuery } from '../api/productBasket';
import { useTranslationQuery } from 'api/translations';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import {
	Area,
	AssortmentType,
	BasketAvailability,
	BasketFilterResponse,
	BasketFilterType,
	BasketGroupingType,
	BasketLineQuantityResponse,
	BasketLineRequest,
	BasketResponse,
	NewProductBasketResponse,
	ProductBasketRequest,
} from 'generated/data-contracts';
import { useViewportSize } from 'helpers/useViewportSize';
import { useBasketLinesUpdate } from '../hooks/useBasketLinesUpdate';
import { NOW_DELIVERY_DATE_VALUE } from '../shared/basketTypes';

export type FreeAssortmentInfo = {
	freeAssortmentSizes: string[];
	is2Dimensional: boolean;
	isOnFreeAssortmentView: boolean;
};

export type ProductBasketContextProps = {
	basketRef: React.RefObject<HTMLDivElement>;
	productId: string;
	isFetching: boolean;
	isSuccess: boolean;
	shouldUseAvailability: boolean;
	basket: BasketResponse;
	preBasket: ProductBasketRequest;
	isSDO: boolean;
	freeAssortmentInfo: FreeAssortmentInfo;
	isSavingBasket: boolean;
	totals: {
		committedQuantity: number;
		uncommittedQuantity: number;
		uncommittedPrice: string;
	};
	assortmentView: {
		availableViews: BasketView[];
		activeView: BasketView | undefined;
		onSelectActiveView: (activeView: BasketView) => void;
	};
	shippingFilter: {
		shippingFilterList: BasketFilterResponse[];
		activeShippingFilter?: string;
		availabilities?: BasketAvailability[];
		onSelectActiveDate: (activeDateValue: string) => void;
	};

	actions: {
		onQuantityUpdate: (newBasketLine: BasketLineRequest) => Promise<BasketLineQuantityResponse | void>;
		clearPreBasket: () => void;
		saveBasket: () => Promise<NewProductBasketResponse | void>;
		bringBasketIntoView: () => void;
	};
};

export enum BasketView {
	BoxBundles = 'boxes',
	FreeAssortmentsMasters = 'freeAssortments',
}

type ProductBasketProviderProps = {
	productId: string;
	children: React.ReactNode;
};

export const SELECT_VIEW_SEARCH_PARAM = 'selectedView';
export const ACTIVE_DATE_SEARCH_PARAM = 'deliveryDate';

const ProductBasketContext = createContext<ProductBasketContextProps | undefined>(undefined);

export const BasketContextProvider: FC<ProductBasketProviderProps> = ({
	productId,
	children,
}: ProductBasketProviderProps) => {
	const { notificationActions } = useNotificationContext();
	const { data: translations } = useTranslationQuery();
	const [searchParams, setSearchParams] = useSearchParams();
	const { isMobile } = useViewportSize();

	const basketRef = useRef<HTMLDivElement>(null);

	const commitBasket = useCommitProductBasketMutation();

	const dateFromUrl = searchParams.get(ACTIVE_DATE_SEARCH_PARAM) || undefined;
	const [activeDate, setActiveDate] = useState<string | undefined>(dateFromUrl);

	const onSelectActiveDate = React.useCallback(
		(activeDateValue: string): void => {
			setActiveDate(activeDateValue);
			setSearchParams(
				(prev) => {
					document.activeElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
					prev.set(ACTIVE_DATE_SEARCH_PARAM, activeDateValue);
					return prev.toString();
				},
				{
					state: { area: Area.Product, productFamilyId: productId },
					replace: true,
				},
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[productId],
	);

	let selectedViewFromUrl: BasketView = BasketView.BoxBundles;
	if (searchParams.get(SELECT_VIEW_SEARCH_PARAM) === BasketView.FreeAssortmentsMasters) {
		selectedViewFromUrl = BasketView.FreeAssortmentsMasters;
	}

	const [selectedView, setSelectedView] = useState<BasketView | undefined>(selectedViewFromUrl);

	const onSelectActiveView = React.useCallback(
		(activeView: BasketView | undefined) => {
			setSelectedView(activeView);
			setSearchParams(
				(prev) => {
					// the !activeView is required for TS
					if (!activeView || isEmpty(activeView)) {
						prev.delete(SELECT_VIEW_SEARCH_PARAM);
						return prev.toString();
					}
					if (prev.get(SELECT_VIEW_SEARCH_PARAM) !== activeView) {
						prev.set(SELECT_VIEW_SEARCH_PARAM, activeView);
						return prev.toString();
					}
					return prev;
				},
				{
					replace: true,
					state: { area: Area.Product, productFamilyId: productId },
				},
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[productId],
	);

	let groupingTypeObject: BasketGroupingType | undefined;
	if (isMobile) groupingTypeObject = BasketGroupingType.ShipTo;
	const [allowedShipTos, setAllowedShipTos] = useState<string[] | undefined>(undefined);
	const updateBasket = useBasketLinesUpdate({
		activeDate,
		productId,
		groupingTypeObject,
		shippingFilter: BasketFilterType.All,
		allowedShipTos: allowedShipTos,
	});
	const { data, isFetching, isSuccess } = useProductBasketQuery({
		familyId: productId,
		basketDeliveryDate: activeDate,
		basketLines: [],
		groupingType: groupingTypeObject,
		shippingFilter: BasketFilterType.All,
	});

	const upsertBasketLine = async (newBasketLine: BasketLineRequest): Promise<BasketLineQuantityResponse | void> => {
		return updateBasket.addBasketLine(newBasketLine);
	};

	const saveBasket = async (): Promise<NewProductBasketResponse | void> => {
		if (updateBasket.prebasketLines.length === 0) return;

		commitBasket.mutate(
			{
				request: {
					familyId: productId,
					basketDeliveryDate: activeDate,
					basketLines: updateBasket.prebasketLines,
					groupingType: groupingTypeObject,
					shippingFilter: BasketFilterType.All,
				},
			},
			{
				onSuccess: ({ data }) => {
					notificationActions.addNotification({
						type: AlertTypes.SUCCESS,
						children: `${data.miniBasketQuantity} ${translations?.preBasket.itemsUpdatedInBasket}`,
					});
					updateBasket.clearBasketLines();
				},
			},
		);
	};

	const bringBasketIntoView = (): void => {
		basketRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	const availableViews = React.useMemo(
		() =>
			data?.basketContents.lookups.availableAssortmentTypes
				.map((r) => {
					if (r === AssortmentType.Boxes) return BasketView.BoxBundles;
					if (r === AssortmentType.FreeAssortments) return BasketView.FreeAssortmentsMasters;
					return undefined;
				})
				.filter((r): r is BasketView => r !== undefined) ?? [],
		[data],
	);

	React.useEffect(() => {
		if (!availableViews || (selectedView && availableViews.includes(selectedView))) return;
		onSelectActiveView(availableViews[0]);
	}, [availableViews, onSelectActiveView, selectedView]);

	React.useEffect(() => {
		const activeFilter = data?.basketContents.lookups.shippingFilters.find((r) => r.isSelected);

		if (!activeFilter || activeDate) return;

		setActiveDate(activeFilter.value);
		setSearchParams(
			(prev) => {
				prev.set(ACTIVE_DATE_SEARCH_PARAM, activeFilter.value);
				return prev;
			},
			{
				replace: true,
				state: { area: Area.Product, productFamilyId: productId },
			},
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, productId]);

	React.useEffect(() => {
		if (!data?.basketContents.basketShipTos) return;
		setAllowedShipTos(data.basketContents.basketShipTos);
	}, [data?.basketContents.basketShipTos]);
	if (data === undefined) {
		return null;
	}

	const basket = data;

	const isSDO = basket.basketContents.basketShipTos.length === 1;

	//temp
	const shippingFilterList = basket.basketContents.lookups.shippingFilters;
	if (shippingFilterList[0]?.value === '') {
		shippingFilterList.shift();
	}

	// preselect shipping filter if not in search params
	let fallbackActiveDate;
	if (isEmpty(activeDate)) {
		fallbackActiveDate = basket.basketContents.lookups.shippingFilters.find((filter) => filter.isSelected)?.value;
	}

	const availabilities = basket.basketContents.lookups.availabilities?.totalSplits;

	const freeAssortmentSizes = basket.basketContents.lookups.families[productId]?.freeAssortmentSizes ?? [];
	const isOnFreeAssortmentView = selectedView === BasketView.FreeAssortmentsMasters;
	const is2Dimensional = Object.values(basket.basketContents.lookups.masters).some((r) => {
		return r.variantsGroupedByLength.some((r) => !isEmpty(r.length));
	});

	const basketContext: ProductBasketContextProps = {
		basketRef,
		productId,
		isFetching,
		shouldUseAvailability: activeDate === NOW_DELIVERY_DATE_VALUE,
		isSuccess,
		totals: {
			committedQuantity: basket.miniBasketQuantity,
			uncommittedQuantity: basket.uncommittedQuantity,
			uncommittedPrice: basket.totalPrice,
		},
		basket: basket.basketContents,
		preBasket: {
			familyId: productId,
			basketDeliveryDate: activeDate,
			basketLines: updateBasket.prebasketLines,
		},
		isSDO,
		freeAssortmentInfo: {
			freeAssortmentSizes,
			is2Dimensional,
			isOnFreeAssortmentView,
		},
		assortmentView: {
			availableViews: availableViews, // we need backend to calculate this
			activeView: selectedView,
			onSelectActiveView,
		},
		shippingFilter: {
			shippingFilterList,
			activeShippingFilter: activeDate || fallbackActiveDate,
			availabilities,
			onSelectActiveDate,
		},
		actions: {
			onQuantityUpdate: upsertBasketLine,
			clearPreBasket: updateBasket.clearBasketLines,
			saveBasket,
			bringBasketIntoView,
		},
		isSavingBasket: commitBasket.isLoading,
	};
	return <ProductBasketContext.Provider value={basketContext}>{children}</ProductBasketContext.Provider>;
};

export const useProductBasketContext = (): ProductBasketContextProps => {
	const context = useContext(ProductBasketContext);

	if (!context) {
		throw new Error(`useProductBasketContext must be used within a ProductBasketContextProvider.`);
	}
	return context;
};
