import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { useTranslationQuery } from 'api/translations';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { ProductBasket as ProductBasketApi } from 'generated/ProductBasket';
import { ErrorResult, ProductBasketRequest, NewProductBasketResponse } from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { isScannerApp } from 'helpers/app';
import { CouldNotLoadProductsError } from '../fallback/errors';

export const useProductBasketQuery = (
	request: ProductBasketRequest,
	host?: string,
	cookies?: string,
): UseQueryResult<NewProductBasketResponse> => {
	const query = useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.productBasket.current(
			request.familyId,
			request.basketDeliveryDate ?? undefined,
			request.groupingType,
		).queryKey,
		queryFn: async (): Promise<NewProductBasketResponse> => {
			const basketApi = new ProductBasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });

			const response = await basketApi
				.productbasketIndexv2Create({
					...request,
					basketDeliveryDate: request.basketDeliveryDate || undefined, //temp fix me
				})
				.catch(() => {
					throw new CouldNotLoadProductsError();
				});
			return response.data;
		},
		suspense: true,
		refetchOnWindowFocus: isScannerApp ? 'always' : true,
		keepPreviousData: true,
		retry: false,
	});

	return query;
};

export const useValidateProductBasketMutation = (): UseMutationResult<
	HttpResponse<NewProductBasketResponse>,
	HttpResponse<ErrorResult>,
	{
		request: ProductBasketRequest;
		host?: string;
		cookies?: string;
	}
> => {
	const { notificationActions } = useNotificationContext();

	const queryClient = useQueryClient();
	const { data: translations } = useTranslationQuery();

	return useMutation({
		mutationFn: async ({ host, cookies, request }) => {
			const basketApi = new ProductBasketApi({
				baseUrl: host,
				baseApiParams: { headers: setHeaders(cookies) },
			});

			return basketApi.productbasketIndexv2Create(request);
		},
		onSuccess: (response, variables) => {
			const { familyId, basketDeliveryDate, groupingType } = variables.request;
			queryClient.setQueryData(
				queryKeys.productBasket.current(familyId, basketDeliveryDate ?? undefined, groupingType).queryKey,
				response.data,
			);
			queryClient.invalidateQueries(queryKeys.basket.miniQuantity._def);
		},
		onError: (err) => {
			if (err.status === 500) {
				notificationActions.addNotification({
					children: translations?.basket.variantQuantityUpdateFailed, // add translation
					type: AlertTypes.DANGER,
				});
			}
		},
	});
};

export const useCommitProductBasketMutation = (): UseMutationResult<
	HttpResponse<NewProductBasketResponse>,
	HttpResponse<ErrorResult>,
	{
		request: ProductBasketRequest;
		host?: string;
		cookies?: string;
	}
> => {
	const { notificationActions } = useNotificationContext();

	const queryClient = useQueryClient();
	const { data: translations } = useTranslationQuery();

	return useMutation({
		mutationFn: async ({ host, cookies, request }) => {
			const basketApi = new ProductBasketApi({
				baseUrl: host,
				baseApiParams: { headers: setHeaders(cookies) },
			});

			return basketApi.productbasketCommitprebasketv2Create(request);
		},
		onSuccess: (response, variables) => {
			const { familyId, basketDeliveryDate, groupingType } = variables.request;
			queryClient.setQueryData(
				queryKeys.productBasket.current(familyId, basketDeliveryDate ?? undefined, groupingType).queryKey,
				response.data,
			);
			queryClient.invalidateQueries(queryKeys.productBasket.current._def);
			queryClient.invalidateQueries(queryKeys.basket.current._def);
			queryClient.invalidateQueries(queryKeys.newBasket.current._def);
			queryClient.invalidateQueries(queryKeys.basket.miniQuantity._def);
			queryClient.invalidateQueries(queryKeys.basket.productCardQuantity._def);
		},
		onError: (err) => {
			if (err.status === 500) {
				notificationActions.addNotification({
					children: translations?.basket.variantQuantityUpdateFailed, // add translation
					type: AlertTypes.DANGER,
				});
			}
		},
	});
};