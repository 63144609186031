/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type AbsoluteUrl = string;

export interface AbsoluteUrlResponse {
  route: AbsoluteUrl;
  /** @format int32 */
  segmentationId: number;
}

export type AccountNumber = string;

export interface ActiveFilterResponse {
  id: string;
  label: string;
  value: string;
  excluded: boolean;
}

export interface AddToFavouriteListRequest {
  productMasterId: ProductMasterId;
  isFreeAssortment: boolean;
  /** @format date */
  deliveryDate: string;
}

export enum Area {
  StaticPages = "StaticPages",
  CMS = "CMS",
  ProductCategory = "ProductCategory",
  Product = "Product",
}

export interface Assembly {
  definedTypes: TypeInfo[];
  exportedTypes: Type[];
  /** @deprecated */
  codeBase?: string | null;
  entryPoint?: MethodInfo;
  fullName?: string | null;
  imageRuntimeVersion: string;
  isDynamic: boolean;
  location: string;
  reflectionOnly: boolean;
  isCollectible: boolean;
  isFullyTrusted: boolean;
  customAttributes: CustomAttributeData[];
  /** @deprecated */
  escapedCodeBase: string;
  manifestModule: Module;
  modules: Module[];
  /** @deprecated */
  globalAssemblyCache: boolean;
  /** @format int64 */
  hostContext: number;
  securityRuleSet: SecurityRuleSet;
}

export enum AssortmentType {
  Boxes = "Boxes",
  FreeAssortments = "FreeAssortments",
}

export interface AudienceTargetingResponse {
  presentationBrandId: PresentationBrandId;
  shipToIds: ShipToId[];
  customerGroupIds: CustomerGroupId[];
  device: BrowserDeviceType;
  locale: string;
}

export interface AvailabilityResponse {
  /** @format int32 */
  totalInBasket: number;
  availabilities: AvailabilityStock[];
}

export interface AvailabilityStock {
  date: string;
  /** @format int32 */
  amount: number;
}

export interface BasketAvailability {
  /** @format date */
  date: string;
  name: string;
  /** @format int32 */
  amount: number;
  /** @format int32 */
  inBasket: number;
}

export interface BasketAvailabilityLookup {
  totalSplits: BasketAvailability[];
  masterSplits: Record<string, BasketAvailabilityMasterSplitResult>;
  availabilities: Record<string, BasketAvailabilityResponse>;
}

export interface BasketAvailabilityMasterSplitResult {
  showBackOrderIcon: boolean;
  showIncomingBackOrderIcon: boolean;
  availabilities: BasketAvailability[];
}

export interface BasketAvailabilityResponse {
  /** @format int32 */
  totalInBasket: number;
  /** @format int32 */
  totalAmount: number;
  availabilities: BasketAvailability[];
}

export interface BasketBrandQuantityResponse {
  /** @format int64 */
  totalQuantity: number;
  deliveryDates: Record<string, BasketShipToProductResponse>;
}

export interface BasketBrandTotalPriceAndQuantityResponse {
  /** @format int64 */
  totalQuantity: number;
  totalPrice: LocalizedPriceWithSymbol;
}

export interface BasketBundleMasterTotalResponse {
  /** @format int32 */
  totalQuantity: number;
  totalPrice: LocalizedPriceWithSymbol;
}

export interface BasketBundleTotalPriceAndQuantityResponse {
  bundleMasterTotalResponses: Record<string, BasketBundleMasterTotalResponse>;
  /** @format int32 */
  totalQuantity: number;
  totalPrice: LocalizedPriceWithSymbol;
}

export interface BasketBundledMasterResponse {
  /** @format int32 */
  totalBundledQuantity: number;
  variants: Record<string, BasketBundledVariantResponse>;
}

export interface BasketBundledVariantResponse {
  /** @format int32 */
  bundledQuantity: number;
}

export interface BasketContentsResponse {
  families: Record<string, BasketProductResponse>;
  shipTos: BasketShipToResponse[];
  shipToQuantities: Record<string, BasketShipToQuantityResponse>;
  productFamilyDeliveryDateTotalResponses: ProductFamilyDeliveryDateTotalResponse;
  shipmentFilters: BasketFilterResponse[];
  groupings: GroupingsResponse;
}

export interface BasketDetailsRequest {
  filterType: BasketFilterType;
  groupingType?: BasketGroupingType;
  /** @format date */
  filterDate?: string | null;
}

export interface BasketDetailsResponse {
  id: BasketId;
  name?: string | null;
  notificationStatus?: string | null;
  salesComment?: string | null;
  /** @format int64 */
  miniBasketQuantity: number;
  /** @format int64 */
  selectedMonthsTotalQuantity: number;
  selectedMonthsTotalPrice: LocalizedPriceWithSymbol;
  subTotal: LocalizedPriceWithSymbol;
  totalPrice: LocalizedPriceWithSymbol;
  currencyCode: string;
  containsInaccessibleProducts: boolean;
  breadCrumbs: BreadCrumbModel[];
  basketContents: BasketContentsResponse;
  redirectRoute?: RouteResponse;
}

export interface BasketErrorResult {
  value?: any;
  formatters: IOutputFormatter[];
  contentTypes: string[];
  declaredType?: Type;
  /** @format int32 */
  statusCode?: number | null;
}

export interface BasketFamily {
  id: ProductFamilyId;
  deliveryDate: string;
  totalQuantity: LocalizedBundledQuantity;
  totalPrice: LocalizedPriceWithSymbol;
  groupImage?: ProductImageResponse;
  shipToQuantity: Record<string, LocalizedBundledQuantity>;
  boxBundles: BoxBundle[];
  freeAssortmentMasters: FreeAssortmentMaster[];
}

export interface BasketFamilyQuantityResponse {
  /** @format int64 */
  totalQuantity: number;
  totalPrice: LocalizedPriceWithSymbol;
  bundles: Record<string, BasketShipToProductBundleResponse>;
}

export interface BasketFamilyTotalPriceAndQuantityResponse {
  bundleTotals: Record<string, BasketBundleTotalPriceAndQuantityResponse>;
  /** @format int64 */
  totalQuantity: number;
  totalPrice: LocalizedPriceWithSymbol;
}

export interface BasketFamilyTotalsResponse {
  familyTotals: Record<string, number>;
}

export interface BasketFilterResponse {
  name: string;
  value: string;
  isSelected: boolean;
  type: BasketFilterType;
  /** @format int64 */
  totalQuantity: number;
}

export enum BasketFilterType {
  DeliveryDate = "DeliveryDate",
  All = "All",
  Now = "Now",
}

export interface BasketGroupingBase {
  children: any[];
  childType: BasketGroupingType;
  id: string;
  totalPrice: LocalizedPriceWithSymbol;
  totalQuantity: LocalizedBundledQuantity;
  shipToQuantity: Record<string, number>;
}

export enum BasketGroupingType {
  ShipTo = "ShipTo",
  Family = "Family",
  PresentationBrand = "PresentationBrand",
}

/** @format int64 */
export type BasketId = number;

export interface BasketLine {
  variantId?: ProductVariantId;
  bundleId: ProductBundleId;
  shipToId: ShipToId;
  /** @format date */
  deliveryDate: string;
  /** @format int32 */
  quantity: number;
  presentationBrandId: PresentationBrandId;
}

export interface BasketLineQuantityResponse {
  variantId?: ProductVariantId;
  bundleId: ProductBundleId;
  shipToId: ShipToId;
  /** @format date */
  deliveryDate: string;
  /**
   * @format int32
   * @min 0
   * @max 999999
   */
  quantity: number;
}

export interface BasketLineRequest {
  variantId?: ProductVariantId;
  productFamilyId?: ProductFamilyId;
  bundleId: ProductBundleId;
  shipToId: ShipToId;
  /** @format date */
  deliveryDate: string;
  shipmentFilterType?: BasketFilterType;
  shipmentFilterValue?: string | null;
  requestedShipmentFilterTypes: BasketFilterType[];
  /**
   * @format int32
   * @min 0
   * @max 999999
   */
  quantity: number;
}

export interface BasketListItemResponse {
  basketId: BasketId;
  createdAt: string;
  name?: string | null;
  salesComment?: string | null;
  creatorName?: string | null;
  /** @format int64 */
  totalQuantity: number;
  isSelected: boolean;
  shipTos?: BasketListItemShipToResponse;
  brands: string[];
}

export interface BasketListItemShipToResponse {
  shipTos: string[];
  /** @format int32 */
  otherShipTosCount: number;
}

export interface BasketListResponse {
  baskets: BasketListItemResponse[];
  filters: BasketSearchResponse[];
}

export interface BasketProductBundleResponse {
  name: string;
  isFreeAssortment: boolean;
  /** @format int32 */
  totalBundledQuantity: number;
  bundleThumbnails: BundleThumbnailResponse[];
  masters: Record<string, BasketBundledMasterResponse>;
}

export interface BasketProductMasterResponse {
  colour: ColourResponse;
  images: ProductImageResponse[];
  freeAssortmentVariantsById: Record<string, VariantResponse>;
  freeAssortmentVariantsByLength: Record<string, VariantResponse[]>;
  splashes: SplashResponse[];
}

export interface BasketProductPricingDetailsResponse {
  price: LocalizedPrice;
  markup: LocalizedPrice;
  recommendedRetailPrice: LocalizedPrice;
}

export interface BasketProductResponse {
  name: string;
  pricingDetails: BasketProductPricingDetailsResponse;
  url?: string | null;
  brandId?: string | null;
  image?: ProductImageResponse;
  bundles: Record<string, BasketProductBundleResponse>;
  masters: Record<string, BasketProductMasterResponse>;
  thumbnails: Record<string, ProductImageResponse>;
  variantIdsBySize: Record<string, ProductVariantId[]>;
  freeAssortmentVariantIdsBySize: Record<string, ProductVariantId[]>;
  allVariantsByLength: Record<string, VariantResponse[]>;
  allowedShipTos: ShipToId[];
}

export interface BasketQuantityRequest {
  basketLines: BasketLineRequest[];
  /** @format date */
  deliveryDate: string;
  shipmentFilterType?: BasketFilterType;
  shipmentFilterValue?: string | null;
  requestedShipmentFilterTypes: BasketFilterType[];
}

export interface BasketQuantityResponse {
  /** @format int64 */
  quantity: number;
}

export interface BasketResponse {
  id: BasketId;
  name?: string | null;
  currencyCode: CurrencyCode;
  basketShipTos: ShipToId[];
  lookups: Lookups;
  grouping: Grouping;
  breadCrumbs: BreadCrumbModel[];
  redirectRoute?: RouteResponse;
  hasUnavailableProducts: boolean;
}

export interface BasketSearchResponse {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  name: string;
  type: FilterType;
  /** @minLength 1 */
  placeholder: string;
  values?: BasketSearchResponseValue[] | null;
}

export interface BasketSearchResponseValue {
  name?: string | null;
  id?: string | null;
  comment?: string | null;
}

export interface BasketShipToProductBundleResponse {
  setQuantityAllowed: boolean;
  /** @format int32 */
  quantity: number;
  totalPrice: LocalizedPriceWithSymbol;
  masters: Record<string, BasketShipToProductMasterResponse>;
}

export interface BasketShipToProductMasterResponse {
  setQuantityAllowed: boolean;
  /** @format int32 */
  totalQuantity: number;
  variants: Record<string, BasketShipToProductVariantResponse>;
}

export interface BasketShipToProductResponse {
  /** @format int64 */
  totalQuantity: number;
  totalPrice: LocalizedPriceWithSymbol;
  families: Record<string, BasketFamilyQuantityResponse>;
}

export interface BasketShipToProductVariantResponse {
  setQuantityAllowed: boolean;
  /** @format int32 */
  totalBundledQuantity: number;
  /** @format int32 */
  quantity: number;
}

export interface BasketShipToQuantityResponse {
  shipToId: ShipToId;
  /** @format int64 */
  totalQuantity: number;
  totalPrice: LocalizedPriceWithSymbol;
  brands: Record<string, BasketBrandQuantityResponse>;
}

export interface BasketShipToResponse {
  shipToId: ShipToId;
  name: string;
  address: string;
}

export interface BasketStarterBoxRestrictionResponse {
  showRestriction: boolean;
  restrictions: Record<string, boolean>;
}

export interface BasketVariant {
  allowedShipTos: Record<string, boolean>;
  shipToQuantity: Record<string, number>;
  incomingOrders: Record<string, LocalizedQuantity>;
}

export interface BoxBundle {
  id: ProductBundleId;
  totalQuantity: LocalizedBundledQuantity;
  bundleSize: string;
  totalPrice: LocalizedPriceWithSymbol;
  allowedShipTos: Record<string, boolean>;
  shipToQuantity: Record<string, number>;
  bundlePricingDetails: NewBasketProductPricingDetailsResponse;
  incomingOrders: Record<string, LocalizedQuantity>;
  totalIncomingOrders: LocalizedQuantity;
}

export interface Brand {
  id: string;
  name: string;
}

export type BrandId = string;

export interface BrandListResponse {
  accountSelectorPath: string;
  brands: BrandResponse[];
}

export interface BrandResponse {
  /** @format int32 */
  presentationBrandId: number;
  brandId: string;
  name: string;
  subBrands: SubBrandResponse[];
  active: boolean;
}

export interface BreadCrumbModel {
  text: string;
  link?: Url;
  route?: ResourceLocation;
}

export enum BrowserDeviceType {
  Desktop = "Desktop",
  Mobile = "Mobile",
  Tablet = "Tablet",
}

export interface BundleThumbnailResponse {
  size100: AbsoluteUrl;
  size450: AbsoluteUrl;
  size1800: AbsoluteUrl;
  altText?: string | null;
}

/** @format int32 */
export type BusinessTradingPartnerId = number;

export enum CallingConventions {
  Standard = "Standard",
  VarArgs = "VarArgs",
  Any = "Any",
  HasThis = "HasThis",
  ExplicitThis = "ExplicitThis",
}

export interface CmsSettingsResponse {
  publicApiKey: string;
  page: string;
  notFound: string;
  internalServerError: string;
  terms: string;
  headerMenu: string;
  footerMenu: string;
  headerUsp: string;
  footerUsp: string;
  socialSection: string;
}

export type CollectionId = string;

export interface CollectionTrackingModelResponse {
  name?: string | null;
  collectionId: CollectionId;
}

export interface ColourResponse {
  name: string;
  hexCode: string;
}

export interface ConstructorInfo {
  name: string;
  declaringType?: Type;
  reflectedType?: Type;
  module: Module;
  customAttributes: CustomAttributeData[];
  isCollectible: boolean;
  /** @format int32 */
  metadataToken: number;
  attributes: MethodAttributes;
  methodImplementationFlags: MethodImplAttributes;
  callingConvention: CallingConventions;
  isAbstract: boolean;
  isConstructor: boolean;
  isFinal: boolean;
  isHideBySig: boolean;
  isSpecialName: boolean;
  isStatic: boolean;
  isVirtual: boolean;
  isAssembly: boolean;
  isFamily: boolean;
  isFamilyAndAssembly: boolean;
  isFamilyOrAssembly: boolean;
  isPrivate: boolean;
  isPublic: boolean;
  isConstructedGenericMethod: boolean;
  isGenericMethod: boolean;
  isGenericMethodDefinition: boolean;
  containsGenericParameters: boolean;
  methodHandle: RuntimeMethodHandle;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  memberType: MemberTypes;
}

export interface CopyBasketRequest {
  name?: string | null;
  copyFromShipToIds: string[];
  copyToShipToIds: string[];
}

export interface CreateBasketRequest {
  selectBasket: boolean;
  name?: string | null;
  shipToIds: string[];
}

export interface CreateOrUpdateBasketRequest {
  shipToIds: ShipToId[];
  returnUrl?: string | null;
}

export interface CreateOrderRequest {
  segmentationId: SegmentationId;
  orderDeliveryChanges: OrderDeliveryChangeResponse[];
}

export interface CreateOrderResponse {
  paymentStatus: OrderPaymentStatus;
  uniqueId: string;
  customerOrderRef?: string | null;
  placedBasketId: BasketId;
  /** @format int64 */
  totalQuantity: number;
  totalPrice: LocalizedPriceWithSymbol;
  newBasketId: BasketId;
}

export interface CreateOrderValidationResponse {
  orderValidationResults: OrderValidationResponse[];
  orderDeliveryChanges: OrderDeliveryChangeResponse[];
}

export type CssClass = string;

export type CurrencyCode = string;

export enum CurrencyPosition {
  Left = "Left",
  Right = "Right",
}

export interface CustomAttributeData {
  attributeType: Type;
  constructor: ConstructorInfo;
  constructorArguments: CustomAttributeTypedArgument[];
  namedArguments: CustomAttributeNamedArgument[];
}

export interface CustomAttributeNamedArgument {
  memberInfo: MemberInfo;
  typedValue: CustomAttributeTypedArgument;
  memberName: string;
  isField: boolean;
}

export interface CustomAttributeTypedArgument {
  argumentType: Type;
  value?: any;
}

export type CustomerGroupId = string;

export interface CustomerGroupTrackingModelResponse {
  customerGroupName?: string | null;
  customerGroupId: CustomerGroupId;
}

export interface DateRange {
  /** @format date */
  from: string | null;
  /** @format date */
  to: string | null;
}

export type DeliveryMonthName = string;

export type EmailAddress = string;

export interface ErrorResult {
  type: ErrorResultType;
  /** @format int32 */
  status: number;
  instance?: string | null;
  title: string;
  detail: string;
  [key: string]: any;
}

export enum ErrorResultType {
  PresentationBrandBadRequest = "PresentationBrandBadRequest",
  PresentationBrandInvalidId = "PresentationBrandInvalidId",
  PresentationBrandNotFound = "PresentationBrandNotFound",
  PresentationBrandNoneSelected = "PresentationBrandNoneSelected",
  SegmentationBadRequest = "SegmentationBadRequest",
  SegmentationNotFound = "SegmentationNotFound",
  SegmentationNoneSelected = "SegmentationNoneSelected",
  SegmentationMismatch = "SegmentationMismatch",
  BasketBadRequest = "BasketBadRequest",
  BasketNotFound = "BasketNotFound",
  BasketForbid = "BasketForbid",
  BasketInvalidId = "BasketInvalidId",
  BasketInvalidModel = "BasketInvalidModel",
  BasketNoneSelected = "BasketNoneSelected",
  ShipToBadRequest = "ShipToBadRequest",
  ShipToInvalidId = "ShipToInvalidId",
}

export enum EventAttributes {
  None = "None",
  SpecialName = "SpecialName",
  RTSpecialName = "RTSpecialName",
}

export interface EventInfo {
  name: string;
  declaringType?: Type;
  reflectedType?: Type;
  module: Module;
  customAttributes: CustomAttributeData[];
  isCollectible: boolean;
  /** @format int32 */
  metadataToken: number;
  memberType: MemberTypes;
  attributes: EventAttributes;
  isSpecialName: boolean;
  addMethod?: MethodInfo;
  removeMethod?: MethodInfo;
  raiseMethod?: MethodInfo;
  isMulticast: boolean;
  eventHandlerType?: Type;
}

export interface ExtraModelsResponse {
  basketFamily: BasketFamily;
  basketGroupingBase: BasketGroupingBase;
}

export interface FavoriteListAddProductRequest {
  barcode: string;
}

export interface FavouriteInitPageRequest {
  segmentationId: SegmentationId;
  sortBy?: string | null;
  sortDirection?: string | null;
  favouriteListId?: FavouriteListHashId;
  groupingType?: FavouriteListGroupingType;
  filters: FilterRequest[];
}

export interface FavouriteListAddProductResponse {
  /** @default "favouriteListUpdated" */
  type?: string;
}

export interface FavouriteListCopyResponse {
  id: FavouriteListHashId;
}

export interface FavouriteListCreateResponse {
  id: FavouriteListHashId;
}

export interface FavouriteListDetailsGetAllByGroupingRequest {
  segmentationId: SegmentationId;
  sortBy?: string | null;
  sortDirection?: string | null;
  favouriteListId?: FavouriteListHashId;
  groupingType?: FavouriteListGroupingType;
}

export interface FavouriteListDetailsGetAllRequest {
  segmentationId: SegmentationId;
  sortBy?: string | null;
  sortDirection?: string | null;
  favouriteListId?: FavouriteListHashId;
}

export interface FavouriteListGroupingItem {
  name: string;
  key: string;
}

export enum FavouriteListGroupingType {
  Ungrouped = "Ungrouped",
  Delivery = "Delivery",
  Category = "Category",
}

export type FavouriteListHashId = string;

export interface FavouriteListInitialResponse {
  favouriteListId: FavouriteListHashId;
  name: string;
  groups: FavouriteListGroupingItem[];
  breadCrumbs: BreadCrumbModel[];
  currentSorting: SortingInformationResponse1;
  sorting: SortingInformationResponse1[];
  isReadOnly: boolean;
  filters: FilterResponse[];
  groupingTypes: FavouriteListGroupingType[];
  currentGroupingType: FavouriteListGroupingType;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalActiveFilterCount: number;
}

export interface FavouriteListItemResponse {
  isActive: boolean;
  id: string;
  /** @format int32 */
  lineCount: number;
  title: string;
  date: string;
}

export interface FavouriteListLineProductKeyModel {
  masterId: ProductMasterId;
  /** @format date */
  deliveryDate: string;
  isFreeAssortment: boolean;
}

export interface FavouriteListPageRequest {
  segmentationId: SegmentationId;
  sortBy?: string | null;
  sortDirection?: string | null;
  favouriteListId?: FavouriteListHashId;
  pageNumber: PageNumber;
  pageSize: PageSize;
  filters: FilterRequest[];
  groupingType: FavouriteListGroupingType;
  groupingKey: string;
}

export interface FavouriteListPagedResponse {
  lines: ProductItemResponse[];
  pagingInformation: PagingInformationResponse;
  status: GetProductsInFavouriteListResult;
}

export interface FavouriteListResponse {
  favouriteLists: FavouriteListItemResponse[];
}

export interface FavouriteShipToFilterResponse {
  id: string;
  name: string;
  values: FavouriteShipToFilterResponseValue[];
  more: boolean;
}

export interface FavouriteShipToFilterResponseValue {
  id: string;
  name: string;
}

export interface FavouriteShipToSearchRequest {
  phrase?: string | null;
  selectedShipToIds?: ShipToId[] | null;
  selectedCustomerGroupIds?: CustomerGroupId[] | null;
  selectedSalesReps?: WebsiteUserId[] | null;
  /** @format int32 */
  page: number;
  /** @format int32 */
  pageSize: number;
}

export enum FeatureFlag {
  Builder = "Builder",
  NewBasket = "NewBasket",
  StockAvailabilityFiltering = "StockAvailabilityFiltering",
  GlobalNewBasket = "GlobalNewBasket",
  MyOrders = "MyOrders",
  EditAppAccounts = "EditAppAccounts",
  MyNoos = "MyNoos",
  PreCreateOrderHistory = "PreCreateOrderHistory",
  ExportAndDownload = "ExportAndDownload",
  OrderDetails = "OrderDetails",
  GlobalFulltextIndex = "GlobalFulltextIndex",
  FakePlaceOrder = "FakePlaceOrder",
  MyOrdersPLP = "MyOrdersPLP",
  NewProductBasket = "NewProductBasket",
  MyInvoices = "MyInvoices",
}

export enum FieldAttributes {
  PrivateScope = "PrivateScope",
  Private = "Private",
  FamANDAssem = "FamANDAssem",
  Assembly = "Assembly",
  Family = "Family",
  FamORAssem = "FamORAssem",
  Public = "Public",
  FieldAccessMask = "FieldAccessMask",
  Static = "Static",
  InitOnly = "InitOnly",
  Literal = "Literal",
  NotSerialized = "NotSerialized",
  HasFieldRVA = "HasFieldRVA",
  SpecialName = "SpecialName",
  RTSpecialName = "RTSpecialName",
  HasFieldMarshal = "HasFieldMarshal",
  PinvokeImpl = "PinvokeImpl",
  HasDefault = "HasDefault",
  ReservedMask = "ReservedMask",
}

export interface FieldInfo {
  name: string;
  declaringType?: Type;
  reflectedType?: Type;
  module: Module;
  customAttributes: CustomAttributeData[];
  isCollectible: boolean;
  /** @format int32 */
  metadataToken: number;
  memberType: MemberTypes;
  attributes: FieldAttributes;
  fieldType: Type;
  isInitOnly: boolean;
  isLiteral: boolean;
  /** @deprecated */
  isNotSerialized: boolean;
  isPinvokeImpl: boolean;
  isSpecialName: boolean;
  isStatic: boolean;
  isAssembly: boolean;
  isFamily: boolean;
  isFamilyAndAssembly: boolean;
  isFamilyOrAssembly: boolean;
  isPrivate: boolean;
  isPublic: boolean;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  fieldHandle: RuntimeFieldHandle;
}

export interface FilterRequest {
  filter?: string | null;
  value?: string | null;
}

export interface FilterResponse {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  name: string;
  type: FilterType;
  /** @format double */
  min?: number | null;
  /** @format double */
  max?: number | null;
  /** @format double */
  selectedMin?: number | null;
  /** @format double */
  selectedMax?: number | null;
  values?: FilterValueResponse[] | null;
}

export enum FilterType {
  String = "String",
  Number = "Number",
}

export interface FilterValueResponse {
  label: string;
  value: string;
  /** @format int32 */
  hitCount?: number | null;
  isSelected: boolean;
}

export interface FreeAssortmentMaster {
  id: ProductMasterId;
  bundleId: ProductBundleId;
  totalQuantity: LocalizedBundledQuantity;
  totalPrice: LocalizedPriceWithSymbol;
  allowedShipTos: Record<string, boolean>;
  shipToQuantity: Record<string, number>;
  variants: Record<string, BasketVariant>;
  freeAssortmentPricingDetails: NewBasketProductPricingDetailsResponse;
  incomingOrders: Record<string, LocalizedQuantity>;
  totalIncomingOrders: LocalizedQuantity;
}

export enum GenericParameterAttributes {
  None = "None",
  Covariant = "Covariant",
  Contravariant = "Contravariant",
  VarianceMask = "VarianceMask",
  ReferenceTypeConstraint = "ReferenceTypeConstraint",
  NotNullableValueTypeConstraint = "NotNullableValueTypeConstraint",
  DefaultConstructorConstraint = "DefaultConstructorConstraint",
  SpecialConstraintMask = "SpecialConstraintMask",
}

export enum GetProductsInFavouriteListResult {
  Success = "Success",
  UserNotFound = "UserNotFound",
  NoPresentationBrand = "NoPresentationBrand",
  BasketNotFound = "BasketNotFound",
  InvalidFavouriteListId = "InvalidFavouriteListId",
}

export interface GlobalProductDataResponse {
  styleId?: string | null;
  styleName?: string | null;
  qualityDescription?: string | null;
  brandId?: string | null;
  brandName?: string | null;
  sleeveName?: string | null;
  fitName?: string | null;
  mainColourCode?: string | null;
  mainColourName?: string | null;
  colourCode?: string | null;
  colourName?: string | null;
  salesDescription?: string | null;
  neckName?: string | null;
  styleOptionNumber?: string | null;
  targetGroups?: string | null;
  productLineId?: ProductLineId;
  masterCategoryId?: ProductCategoryId;
  masterCategoryName?: string | null;
  masterSubCategoryId?: ProductCategoryId;
  masterSubCategoryName?: string | null;
  materials: MaterialTrackingModelResponse[];
  colourVariantText?: string | null;
  productLineName?: string | null;
  pantoneCode?: string | null;
  styleOptionName?: string | null;
  sapColour?: string | null;
  patternId?: string | null;
  patternName?: string | null;
  businessModelId?: string | null;
  businessModelName?: string | null;
  collections: CollectionTrackingModelResponse[];
}

export interface Grouping {
  children: any[];
  childType: BasketGroupingType;
}

export interface GroupingBrandResponse {
  brandId: BrandId;
  /** @format int32 */
  totalProductsQuantity: number;
  deliveryDates: GroupingDeliveryDateResponse[];
}

export interface GroupingDeliveryDateResponse {
  id: string;
  name: string;
  families: Record<string, GroupingFamilyResponse>;
}

export interface GroupingFamilyResponse {
  bundles: ProductBundleId[];
  masters: ProductMasterId[];
}

export interface GroupingRequest {
  type: OrderHistorySearchGroupingType;
  value: string;
}

export interface GroupingResponse {
  totalValue: string | null;
  /** @format int32 */
  totalQuantity: number;
  displayName: string | null;
  id: string | null;
}

export interface GroupingsResponse {
  brands: GroupingBrandResponse[];
}

export type ICustomAttributeProvider = object;

export type IOutputFormatter = object;

export interface ImageLookupRequest {
  /** The list of file ids to lookup. */
  images: ImageLookupRequestItem[];
}

export interface ImageLookupRequestItem {
  /**
   * The file id to lookup.
   * @format uuid
   */
  fileId: string;
  /**
   * The optional cropping id to lookup for the file.
   * @format uuid
   */
  croppingId?: string | null;
  /** The optional path to lookup for the file if no id matches. */
  filePath?: string | null;
}

export interface ImageLookupResponse {
  /** The list files that matched the ids. */
  images: ImageLookupResponseItem[];
}

export interface ImageLookupResponseItem {
  /**
   * The file id that was looked up.
   * @format uuid
   */
  fileId: string;
  /**
   * The optional cropping id that was looked up for the file.
   * @format uuid
   */
  croppingId: string | null;
  /** The optional path that was looked up for the file if no id matched. */
  filePath: string | null;
  altText?: string | null;
  size100: ImageSizeResponse;
  size450: ImageSizeResponse;
  size1800: ImageSizeResponse;
}

export interface ImageSizeResponse {
  /** @format int32 */
  height?: number | null;
  /** @format int32 */
  width?: number | null;
  url: AbsoluteUrl;
}

export type IntPtr = object;

export type InvoiceNumber = string;

export interface LayoutBrandOptions {
  hideBreadCrumbs: boolean;
  hideSearch: boolean;
  hideCategories: boolean;
  hideBrandDropdownSelection: boolean;
}

export enum LayoutKind {
  Sequential = "Sequential",
  Explicit = "Explicit",
  Auto = "Auto",
}

export interface LayoutResponse {
  megaMenu: MegaMenuModel;
  staticLinks: StaticLinkModel[];
  frontPageUrl: string;
  /** @format int32 */
  languageId: number;
  cultureCode: string;
  currencySymbol: string;
  currencyName: string;
  currencyPosition: CurrencyPosition;
  user?: UserInfoResponse;
  segmentations: SegmentationResponse[];
  selectedBrandId?: string | null;
  layoutBrandOptions?: LayoutBrandOptions;
  cmsSettings: CmsSettingsResponse;
  audienceTargeting: AudienceTargetingResponse;
  userFeatureFlags: FeatureFlag[];
  currentFavouriteListId?: FavouriteListHashId;
}

export type LocalizedBundledQuantity = string;

export type LocalizedPrice = string;

export type LocalizedPriceWithSymbol = string;

export type LocalizedQuantity = string;

export interface Lookups {
  totals: {
    Basket: Total;
    ShippingFilter: Total;
  };
  shipToTotals: Record<string, Total>;
  shipTos: Record<string, ShipToDetails>;
  families: Record<string, NewBasketProductFamilyResponse>;
  masters: Record<string, NewBasketProductMasterResponse>;
  bundles: Record<string, NewBasketProductBundleResponse>;
  restrictions: Record<string, BasketStarterBoxRestrictionResponse>;
  shippingFilters: BasketFilterResponse[];
  availabilities: BasketAvailabilityLookup;
  availableAssortmentTypes: AssortmentType[];
  incomingOrders: Record<string, LocalizedQuantity>;
}

export interface MaterialTrackingModelResponse {
  name?: string | null;
  /** @format int32 */
  percentage?: number | null;
}

export interface MegaMenuItem {
  /** @format uuid */
  id?: string | null;
  name: string;
  menuItemClass?: CssClass;
  linkUrl?: Url;
  explicitUrlTarget?: string | null;
  route?: ResourceLocation;
  selected: boolean;
  children: MegaMenuItem[];
}

export interface MegaMenuModel {
  /** @format uuid */
  menuId: string;
  menuName: string;
  subTreeParentName: string;
  children: MegaMenuItem[];
}

export interface MemberInfo {
  memberType: MemberTypes;
  name: string;
  declaringType?: Type;
  reflectedType?: Type;
  module: Module;
  customAttributes: CustomAttributeData[];
  isCollectible: boolean;
  /** @format int32 */
  metadataToken: number;
}

export enum MemberTypes {
  Constructor = "Constructor",
  Event = "Event",
  Field = "Field",
  Method = "Method",
  Property = "Property",
  TypeInfo = "TypeInfo",
  Custom = "Custom",
  NestedType = "NestedType",
  All = "All",
}

export enum MethodAttributes {
  PrivateScope = "PrivateScope",
  Private = "Private",
  FamANDAssem = "FamANDAssem",
  Assembly = "Assembly",
  Family = "Family",
  FamORAssem = "FamORAssem",
  Public = "Public",
  MemberAccessMask = "MemberAccessMask",
  UnmanagedExport = "UnmanagedExport",
  Static = "Static",
  Final = "Final",
  Virtual = "Virtual",
  HideBySig = "HideBySig",
  NewSlot = "NewSlot",
  CheckAccessOnOverride = "CheckAccessOnOverride",
  Abstract = "Abstract",
  SpecialName = "SpecialName",
  RTSpecialName = "RTSpecialName",
  PinvokeImpl = "PinvokeImpl",
  HasSecurity = "HasSecurity",
  RequireSecObject = "RequireSecObject",
  ReservedMask = "ReservedMask",
}

export interface MethodBase {
  memberType: MemberTypes;
  name: string;
  declaringType?: Type;
  reflectedType?: Type;
  module: Module;
  customAttributes: CustomAttributeData[];
  isCollectible: boolean;
  /** @format int32 */
  metadataToken: number;
  attributes: MethodAttributes;
  methodImplementationFlags: MethodImplAttributes;
  callingConvention: CallingConventions;
  isAbstract: boolean;
  isConstructor: boolean;
  isFinal: boolean;
  isHideBySig: boolean;
  isSpecialName: boolean;
  isStatic: boolean;
  isVirtual: boolean;
  isAssembly: boolean;
  isFamily: boolean;
  isFamilyAndAssembly: boolean;
  isFamilyOrAssembly: boolean;
  isPrivate: boolean;
  isPublic: boolean;
  isConstructedGenericMethod: boolean;
  isGenericMethod: boolean;
  isGenericMethodDefinition: boolean;
  containsGenericParameters: boolean;
  methodHandle: RuntimeMethodHandle;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
}

export enum MethodImplAttributes {
  IL = "IL",
  Native = "Native",
  OPTIL = "OPTIL",
  CodeTypeMask = "CodeTypeMask",
  ManagedMask = "ManagedMask",
  NoInlining = "NoInlining",
  ForwardRef = "ForwardRef",
  Synchronized = "Synchronized",
  NoOptimization = "NoOptimization",
  PreserveSig = "PreserveSig",
  AggressiveInlining = "AggressiveInlining",
  AggressiveOptimization = "AggressiveOptimization",
  InternalCall = "InternalCall",
  MaxMethodImplVal = "MaxMethodImplVal",
}

export interface MethodInfo {
  name: string;
  declaringType?: Type;
  reflectedType?: Type;
  module: Module;
  customAttributes: CustomAttributeData[];
  isCollectible: boolean;
  /** @format int32 */
  metadataToken: number;
  attributes: MethodAttributes;
  methodImplementationFlags: MethodImplAttributes;
  callingConvention: CallingConventions;
  isAbstract: boolean;
  isConstructor: boolean;
  isFinal: boolean;
  isHideBySig: boolean;
  isSpecialName: boolean;
  isStatic: boolean;
  isVirtual: boolean;
  isAssembly: boolean;
  isFamily: boolean;
  isFamilyAndAssembly: boolean;
  isFamilyOrAssembly: boolean;
  isPrivate: boolean;
  isPublic: boolean;
  isConstructedGenericMethod: boolean;
  isGenericMethod: boolean;
  isGenericMethodDefinition: boolean;
  containsGenericParameters: boolean;
  methodHandle: RuntimeMethodHandle;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  memberType: MemberTypes;
  returnParameter: ParameterInfo;
  returnType: Type;
  returnTypeCustomAttributes: ICustomAttributeProvider;
}

export interface Module {
  assembly: Assembly;
  fullyQualifiedName: string;
  name: string;
  /** @format int32 */
  mdStreamVersion: number;
  /** @format uuid */
  moduleVersionId: string;
  scopeName: string;
  moduleHandle: ModuleHandle;
  customAttributes: CustomAttributeData[];
  /** @format int32 */
  metadataToken: number;
}

export interface ModuleHandle {
  /** @format int32 */
  mdStreamVersion: number;
}

export interface NewBasketBundledMasterResponse {
  id: ProductMasterId;
  /** @format int32 */
  totalBundledQuantity: number;
  variantQuantityById: Record<string, number>;
}

export interface NewBasketProductBundleResponse {
  name: string;
  /** @format int32 */
  totalBundledQuantity: number;
  bundleThumbnails: BundleThumbnailResponse[];
  masters: NewBasketBundledMasterResponse[];
  sizes: string[];
}

export interface NewBasketProductFamilyResponse {
  styleNumber?: string | null;
  name: string;
  pricingDetails: NewBasketProductPricingDetailsResponse;
  url?: string | null;
  brandId?: BrandId;
  image?: ProductImageResponse;
  freeAssortmentSizes: string[];
}

export interface NewBasketProductMasterResponse {
  colour: ColourResponse;
  images: ProductImageResponse[];
  splashes: SplashResponse[];
  sizes: string[];
  variantsGroupedByLength: NewVariantGroup[];
}

export interface NewBasketProductPricingDetailsResponse {
  price: LocalizedPrice;
  markup: LocalizedPrice;
  recommendedRetailPrice: LocalizedPrice;
}

export interface NewProductBasketResponse {
  /** @format int32 */
  uncommittedQuantity: number;
  totalPrice: LocalizedPriceWithSymbol;
  /** @format int64 */
  miniBasketQuantity: number;
  basketContents: BasketResponse;
  basketLines: BasketLine[];
}

export interface NewVariantGroup {
  length: string;
  variantsBySize: Record<string, ProductVariantId[]>;
}

export interface NoPresentationBrandResult {
  /** @format int32 */
  statusCode: number;
  /** @default "NoPresentationBrand" */
  errorDescriptor?: string;
}

export interface OpenGraphMetadata {
  title: string;
  description: string;
  image?: AbsoluteUrl;
  type: string;
  url?: AbsoluteUrl;
}

export interface OrderDeliveryChangeResponse {
  styleNumber?: string | null;
  variantId?: ProductVariantId;
  bundleId?: ProductBundleId;
  shipToId?: ShipToId;
  /** @format date */
  deliveryDate?: string | null;
  validationResults: OrderDeliveryChangeValidationResponse[];
}

export interface OrderDeliveryChangeValidationResponse {
  assortmentName: string;
  /** @format date */
  newDate: string;
  /** @format int32 */
  newQuantity: number;
}

export interface OrderHistoryAccountStatusResponse {
  haveInboundOrders: AccountNumber[];
  haveDeliveredOrders: AccountNumber[];
}

export interface OrderHistoryDetailLineRequest {
  pageNumber?: PageNumber;
  pageSize?: PageSize;
  orderNumber: OrderNumber;
}

export interface OrderHistoryFamilySearchRequest {
  familyId: ProductFamilyId;
  bundleId?: ProductBundleId;
  masterId?: ProductMasterId;
  /** @format date */
  searchBackDate: string;
  shipToIds: ShipToId[];
}

export interface OrderHistoryGroupingRequest {
  phrase?: string | null;
  shipToIds?: ShipToId[] | null;
  status: OrderHistorySearchGroup;
  filters?: FilterRequest[] | null;
  collapseGroups: GroupingRequest[];
  groupingType: OrderHistorySearchGroupingType;
}

export interface OrderHistoryGroupingResponse {
  totalValue: string | null;
  /** @format int32 */
  totalQuantity: number;
  sorting: SortingResponseOrderHistorySort[];
  filters: FilterResponse[];
  groupings: GroupingResponse[];
}

export interface OrderHistoryInvoiceResponse {
  invoiceNumber: InvoiceNumber;
  shipToId: ShipToId;
}

export interface OrderHistoryInvoiceSearchRequest {
  phrase?: string | null;
  shipToIds?: ShipToId[] | null;
  invoiceNumber: InvoiceNumber;
  filters?: FilterRequest[] | null;
}

export interface OrderHistoryInvoiceSearchResponse {
  orderLines: OrderLineResponse[];
}

export interface OrderHistoryLineExportRequest {
  phrase?: string | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  shipToIds?: ShipToId[] | null;
  status?: OrderHistorySearchGroup;
  filters?: FilterRequest[] | null;
  orderNumbers?: OrderNumber[] | null;
  invoiceNumbers?: InvoiceNumber[] | null;
}

export interface OrderHistoryLineSearchRequest {
  pageNumber?: PageNumber;
  pageSize?: PageSize;
  phrase?: string | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  shipToIds?: ShipToId[] | null;
  status: OrderHistorySearchGroup;
  filters?: FilterRequest[] | null;
  collapseGroups: GroupingRequest[];
  familyIds?: ProductFamilyId[] | null;
  masterIds?: ProductMasterId[] | null;
  bundleIds?: ProductBundleId[] | null;
}

export interface OrderHistoryLineSearchResponse {
  phrase: string | null;
  pagingInformation: PagingInformationResponse;
  totalValue: string | null;
  /** @format int32 */
  totalQuantity: number;
  filters: FilterResponse[];
  orderLines: OrderLineResponse[];
}

export interface OrderHistoryProductMasterResponse {
  id?: string | null;
  name?: string | null;
  colour?: ColourResponse;
  url?: string | null;
  image?: ProductImageResponse;
  variants: OrderHistoryProductVariantResponse[];
  splashes: SplashResponse[];
  isNoos?: boolean | null;
}

export interface OrderHistoryProductResponse {
  styleNumber: string;
  styleName?: string | null;
  brandName?: string | null;
  url?: string | null;
  primaryImage?: ProductImageResponse;
  primaryColour?: ColourResponse;
  productImages: ProductImageResponse[];
  masters: OrderHistoryProductMasterResponse[];
}

export interface OrderHistoryProductVariantResponse {
  /** @format int32 */
  quantity: number;
  size: string;
  length?: string | null;
}

export enum OrderHistorySearchGroup {
  All = "All",
  Inbound = "Inbound",
  Delivered = "Delivered",
}

export enum OrderHistorySearchGroupingType {
  ShipTo = "ShipTo",
  DeliveryMonth = "DeliveryMonth",
  Category = "Category",
}

export interface OrderHistorySearchResponse {
  orderNumber: string;
  platform: string | null;
  account: string;
  brand: string | null;
  orderReference: string | null;
  clientReference: string | null;
  basketId: BasketId;
  createdDate: string | null;
  totalValue?: string | null;
  /** @format int32 */
  totalQuantity: number;
}

export enum OrderHistorySort {
  OrderNumber = "OrderNumber",
  StyleNumber = "StyleNumber",
  EstimatedDeliveryDate = "EstimatedDeliveryDate",
  DeliveryDate = "DeliveryDate",
  UnitNetPrice = "UnitNetPrice",
  OrderLineNetPrice = "OrderLineNetPrice",
  OrderLineQuantityPieces = "OrderLineQuantityPieces",
  SalesChannelName = "SalesChannelName",
  BasketCreatorName = "BasketCreatorName",
}

/** @format int32 */
export type OrderId = number;

export interface OrderInvoiceResponse {
  invoiceNumber: InvoiceNumber;
  invoiceDate: string | null;
  shipToName: string | null;
  shipToId: ShipToId;
  orderNumbers: OrderNumber[];
  totalValue?: string | null;
  /** @format int64 */
  totalQuantity: number;
  deliveries: TrackAndTraceResponse[];
}

export interface OrderInvoiceSearchRequest {
  pageNumber?: PageNumber;
  pageSize?: PageSize;
  phrase?: string | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  shipToIds?: ShipToId[] | null;
  filters?: FilterRequest[] | null;
  dateRange?: DateRange;
}

export interface OrderInvoiceSearchResponse {
  phrase: string | null;
  pagingInformation: PagingInformationResponse;
  totalValue: string | null;
  /** @format int32 */
  totalQuantity: number;
  currentSorting: SortingResponseOrderInvoiceSort;
  sorting: SortingResponseOrderInvoiceSort[];
  filters: FilterResponse[];
  invoices: OrderInvoiceResponse[];
  /**
   * If more than 50 invoices are found, the export functionality is disabled.
   * Unless the export is for a period of no more than one year and one account.
   */
  exportAvaliable: boolean;
}

export enum OrderInvoiceSort {
  InvoiceNumber = "InvoiceNumber",
  InvoiceDate = "InvoiceDate",
}

export interface OrderLineResponse {
  orderNumber?: OrderNumber;
  basketId?: BasketId;
  status?: string | null;
  estimatedShippingDate?: string | null;
  warehouseShippingETA?: string | null;
  platform?: string | null;
  isDelivered: boolean;
  isFreeAssortment?: boolean | null;
  /** @format int32 */
  orderLineQuantityPieces: number;
  /** @format int32 */
  bundleQuantity: number;
  /** @format int32 */
  bundleSize: number;
  /** @format double */
  unitPrice: number;
  /** @format double */
  markupPrice: number | null;
  /** @format double */
  wholeSalePrice: number;
  /** @format double */
  rrp: number | null;
  discount: string;
  orderLineNetPrice: string;
  trackAndTrace: TrackAndTraceResponse[];
  invoices: OrderHistoryInvoiceResponse[];
  product?: OrderHistoryProductResponse;
}

export type OrderNumber = string;

export enum OrderPaymentStatus {
  Created = "Created",
  AwaitingProviderApproval = "AwaitingProviderApproval",
  Paid = "Paid",
  Failed = "Failed",
}

export interface OrderValidationResponse {
  styleNumber?: string | null;
  variantId?: ProductVariantId;
  bundleId?: ProductBundleId;
  shipToId?: ShipToId;
  /** @format date */
  deliveryDate?: string | null;
  validationResults: ValidationResponse[];
}

export interface PDFDownloadCard {
  brandName: string | null;
  styleNumber: string;
  familyName: string | null;
  bundleName: string | null;
  categoryId: ProductCategoryId;
  categoryName: string | null;
  recommendedRetailPrice: LocalizedPrice;
  wholeSalePrice: LocalizedPrice;
  markup: LocalizedPrice;
  images: ProductImageResponse[];
  currencyCode: CurrencyCode;
  primaryImage?: ProductImageResponse;
  splashes: SplashResponse[];
  primaryColour?: ColourResponse;
}

export interface PDFDownloadCardWithQuantity {
  brandName: string | null;
  styleNumber: string;
  familyName: string | null;
  bundleName: string | null;
  categoryId: ProductCategoryId;
  categoryName: string | null;
  recommendedRetailPrice: LocalizedPrice;
  wholeSalePrice: LocalizedPrice;
  markup: LocalizedPrice;
  images: ProductImageResponse[];
  currencyCode: CurrencyCode;
  primaryImage?: ProductImageResponse;
  splashes: SplashResponse[];
  primaryColour?: ColourResponse;
  /** @format date */
  warehouseEta: string | null;
  referenceNumber: string | null;
  boxQuantity: LocalizedQuantity;
  variantQuantity: LocalizedQuantity;
  total: LocalizedPrice;
}

export interface PDFDownloadHeader {
  accountName?: string | null;
  accountNumber: AccountNumber;
  accountAddressLineOne?: string | null;
  accountAddressLineTwo?: string | null;
  printDate: string;
  orderStatus?: string | null;
}

export interface PDFDownloadNode {
  children?: Record<string, PDFDownloadNode>;
  details?: PDFDownloadCard[] | null;
}

export interface PDFDownloadResponse {
  groupings: PDFDownloadNode;
}

export interface PDFDownloadSummary {
  lines: PDFDownloadSummaryLine[];
  totalQuantity: LocalizedQuantity;
  total: LocalizedPrice;
  currencyCode: CurrencyCode;
}

export interface PDFDownloadSummaryLine {
  month: DeliveryMonthName;
  /** @format int32 */
  year: number;
  totalQuantity: LocalizedQuantity;
  total: LocalizedPrice;
}

export interface PDFDownloadWithQuantityNode {
  total: LocalizedPrice;
  totalVariantQuantity: LocalizedQuantity;
  totalBoxQuantity: LocalizedQuantity;
  children?: Record<string, PDFDownloadWithQuantityNode>;
  details?: PDFDownloadCardWithQuantity[] | null;
}

export interface PDFDownloadWithSummaryResponse {
  header: PDFDownloadHeader;
  groupings: PDFDownloadWithQuantityNode;
  summary: PDFDownloadSummary;
}

export interface PDFDownloadWithSummaryResponseWrapper {
  pdfDownloadWithSummaryResponses: Record<string, PDFDownloadWithSummaryResponse>;
}

export interface PageMetadata {
  title: string;
  description: string;
  keywords: string;
  canonicalLink?: AbsoluteUrl;
  noIndex: boolean;
  noFollow: boolean;
  openGraph?: OpenGraphMetadata;
}

/** @format int32 */
export type PageNumber = number;

/** @format int32 */
export type PageSize = number;

export interface PageTrackingDataResponse {
  userEmail: EmailAddress;
  userName: string | null;
  userId: WebsiteUserId;
  role: string | null;
  basketId: BasketId;
  brandName: string | null;
  subBrandName: string | null;
  languageName: string;
  productLines: ProductLineId[] | null;
  isMdo: boolean;
  currencyShown: string;
  shipTos: ShipToTrackingModelResponse[];
}

export enum PageType {
  Basket = "Basket",
  Checkout = "Checkout",
  Search = "Search",
  SearchApp = "SearchApp",
  ArticleList = "ArticleList",
  FavouriteList = "FavouriteList",
  UserSignIn = "UserSignIn",
  UserRegister = "UserRegister",
  UserLoginConfirmEmail = "UserLoginConfirmEmail",
  UserConfirmEmailSetPassword = "UserConfirmEmailSetPassword",
  UserForgotPassword = "UserForgotPassword",
  UserResetPassword = "UserResetPassword",
  CmsPreview = "CmsPreview",
  BrandSelector = "BrandSelector",
  AccountSelector = "AccountSelector",
  Profile = "Profile",
  All = "All",
  Orders = "Orders",
  EditAccounts = "EditAccounts",
  AppFlowCompleted = "AppFlowCompleted",
  OrderDetail = "OrderDetail",
  PreLogin = "PreLogin",
  Invoices = "Invoices",
}

export interface PagingInformationResponse {
  currentPage: PageNumber;
  pageSize: PageSize;
  /** @format int64 */
  totalNumberOfItems: number;
}

export enum ParameterAttributes {
  None = "None",
  In = "In",
  Out = "Out",
  Lcid = "Lcid",
  Retval = "Retval",
  Optional = "Optional",
  HasDefault = "HasDefault",
  HasFieldMarshal = "HasFieldMarshal",
  Reserved3 = "Reserved3",
  Reserved4 = "Reserved4",
  ReservedMask = "ReservedMask",
}

export interface ParameterInfo {
  attributes: ParameterAttributes;
  member: MemberInfo;
  name?: string | null;
  parameterType: Type;
  /** @format int32 */
  position: number;
  isIn: boolean;
  isLcid: boolean;
  isOptional: boolean;
  isOut: boolean;
  isRetval: boolean;
  defaultValue?: any;
  rawDefaultValue?: any;
  hasDefaultValue: boolean;
  customAttributes: CustomAttributeData[];
  /** @format int32 */
  metadataToken: number;
}

export interface PostConfigResponse {
  tabs: PostConfigTabResponse[];
  webViewUrls: PostConfigWebViewUrlsResponse;
  translations: PostConfigTranslationResponse;
  presentationBrandLogoURI: string | null;
  presentationBrandId: PresentationBrandId;
  showSubBrands: boolean;
}

export interface PostConfigTabResponse {
  title: string;
  url: string;
}

export interface PostConfigTranslationResponse {
  scannerDescription: string;
  scanToSearch: string;
  item: string;
  basket: string;
  chooseBrand: string;
  chooseSubBrand: string;
  enterBrand: string;
  enterSubBrand: string;
  missingBrand: string;
  manageAccounts: string;
  missingBrandManageAccounts: string;
  alertScannerNoAccessCameraTitle: string;
  alertScannerNoAccessCameraMessage: string;
  invalidBarcode: string;
  alertNo: string;
  alertYesGoToSettings: string;
  ok: string;
  dialogVersionTitle: string;
  dialogVersionMessage: string;
  offlineMessage: string;
  productNotFound: string;
  noPermissionsToProduct: string;
  productAlreadyOnList: string;
  scanErrorTitle: string;
  genericScanError: string;
}

export interface PostConfigWebViewUrlsResponse {
  favoriteList: string;
  basket: string;
  accountSelector: string;
}

export interface PreConfigResponse {
  cookiesToShare: string[];
  presentationBrandLogoURIs: string[];
}

/** @format int32 */
export type PresentationBrandId = number;

export interface PresentationBrandResponse {
  presentationBrandId: PresentationBrandId;
  name: string;
  brandLogoUri?: string | null;
  active: boolean;
  subBrands: PresentationSubBrandResponse[];
}

export interface PresentationBrandsResponse {
  brands: PresentationBrandResponse[];
}

export interface PresentationSubBrandResponse {
  presentationBrandId: PresentationBrandId;
  name: string;
  brandLogoUri?: string | null;
}

export interface PriceSliderResponse {
  /** @format double */
  minPrice?: number | null;
  /** @format double */
  maxPrice?: number | null;
  /** @format double */
  selectedMinPrice?: number | null;
  /** @format double */
  selectedMaxPrice?: number | null;
  currencySymbol?: string | null;
}

export interface PricingDetailsResponse {
  recommendedRetailPrice: LocalizedPrice;
  price: LocalizedPrice;
  originalPrice?: LocalizedPrice;
  invariantPrice: string;
  markup: LocalizedPrice;
  discount: string;
  currencyCode: string;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ProductBasketRequest {
  basketLines: BasketLineRequest[];
  familyId: ProductFamilyId;
  shippingFilter?: BasketFilterType;
  groupingType?: BasketGroupingType;
  /** @format date */
  basketDeliveryDate?: string | null;
}

export interface ProductBasketResponse {
  /** @format int32 */
  uncommittedQuantity: number;
  totalPrice: LocalizedPriceWithSymbol;
  /** @format int64 */
  miniBasketQuantity: number;
  basketContents: BasketContentsResponse;
  lookups: Lookups;
}

export type ProductBundleId = string;

export type ProductCategoryId = string;

export interface ProductCategoryValue {
  id: ProductCategoryId;
  name: string;
}

export interface ProductFamilyDeliveryDateTotalResponse {
  familyTotals: Record<string, Record<string, BasketFamilyTotalPriceAndQuantityResponse>>;
  brandTotals: Record<string, BasketBrandTotalPriceAndQuantityResponse>;
}

export type ProductFamilyId = string;

export interface ProductFileResponse {
  name: string;
  fileType: string;
  url: string;
}

export interface ProductFilter {
  attributeKey: string;
  attributeValues: string[];
}

export interface ProductImageResponse {
  size100: AbsoluteUrl;
  size450: AbsoluteUrl;
  size1800: AbsoluteUrl;
  altText?: string | null;
}

export interface ProductItemResponse {
  id: string;
  styleNumber: string;
  name: string;
  brand: string;
  url?: string | null;
  isFavourited: boolean | null;
  category?: ProductCategoryValue;
  pricingDetails: PricingDetailsResponse;
  primaryImage: ProductImageResponse;
  primaryColour: ColourResponse;
  favouriteListCases: FavouriteListLineProductKeyModel[];
  masters: ProductMasterItemResponse[];
  splashes: SplashResponse[];
  orderDetails: ProductOrderDetails[];
}

export type ProductLineId = string;

export interface ProductListCategoryResponse {
  id: ProductCategoryId;
  name: string;
  description: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  seoDescription: string;
  breadcrumbs: BreadCrumbModel[];
}

export interface ProductListResponse {
  phrase: string | null;
  didYouMean: string | null;
  products: ProductItemResponse[];
  filters: FilterResponse[];
  activeFilters: ActiveFilterResponse[];
  currentSorting: SortingInformationResponse1;
  pagingInformation: PagingInformationResponse;
  category: ProductListCategoryResponse;
  sorting: SortingInformationResponse1[];
  priceSliderValues: PriceSliderResponse;
  productCategoryIds: ProductCategoryId[];
  pageMetadata: PageMetadata;
  productHitsByCategoryId: Record<string, number>;
  hasCategory: boolean;
}

export type ProductMasterId = string;

export interface ProductMasterItemResponse {
  id: string;
  name: string;
  colour: ColourResponse;
  url: string;
  isFreeAssortment: boolean;
  /** @format date */
  deliveryDate: string;
  primaryImage?: ProductImageResponse;
  images?: ProductImageResponse[] | null;
  hasImage: boolean;
}

export interface ProductMasterResponse {
  /** @minLength 1 */
  id: string;
  colour: ColourResponse;
  name: string;
  images: ProductImageResponse[];
  hasImage: boolean;
  splashes: SplashResponse[];
  pricingDetails?: PricingDetailsResponse;
}

export interface ProductOrderDetails {
  /** @format int64 */
  quantity: number;
  type: ProductOrderType;
  inMultipleAccounts: boolean;
}

export enum ProductOrderType {
  Inbound = "Inbound",
  Delivered = "Delivered",
}

export interface ProductResponse {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  styleNumber: string;
  name: string;
  brand: string;
  description: string;
  qualityDescription: string;
  salesDescription: string;
  sustainabilityDescription: string;
  pricingDetails: PricingDetailsResponse;
  images: ProductImageResponse[];
  videos: ProductVideoResponse[];
  files: ProductFileResponse[];
  specs: ProductSpec[];
  masters: ProductMasterResponse[];
  breadcrumbs: BreadCrumbModel[];
  pageMetadata: PageMetadata;
  favouriteListCases: FavouriteListLineProductKeyModel[];
  splashes: SplashResponse[];
}

export interface ProductSearchRequest {
  /** @format int32 */
  skip: number;
  /** @format int32 */
  take: number;
  sortBy: ProductSort;
  sortDirection: SortDirection;
  phrase?: string | null;
  /** @format double */
  minPrice?: number | null;
  /** @format double */
  maxPrice?: number | null;
  productFilters?: ProductFilter[] | null;
  productCategoryId?: ProductCategoryId;
  customerGroupIds?: CustomerGroupId[] | null;
  productLineIds?: ProductLineId[] | null;
  collectionIds?: CollectionId[] | null;
  predefinedProductFilters?: ProductFilter[] | null;
  predefinedProductCategoryIds?: ProductCategoryId[] | null;
  productCatalogues?: string[] | null;
}

export enum ProductSort {
  Name = "Name",
  Relevance = "Relevance",
  Rrp = "Rrp",
  Markup = "Markup",
  WholesalePrice = "WholesalePrice",
  ProductIdOrder = "ProductIdOrder",
  MasterIdOrder = "MasterIdOrder",
}

export interface ProductSpec {
  /** @minLength 1 */
  name: string;
  value: string;
}

export interface ProductTrackingDataResponse {
  globalProductDataResponse: GlobalProductDataResponse;
  pageTrackingData: PageTrackingDataResponse;
}

export type ProductVariantId = string;

export interface ProductVideoResponse {
  title: string;
  url: string;
}

export enum PropertyAttributes {
  None = "None",
  SpecialName = "SpecialName",
  RTSpecialName = "RTSpecialName",
  HasDefault = "HasDefault",
  Reserved2 = "Reserved2",
  Reserved3 = "Reserved3",
  Reserved4 = "Reserved4",
  ReservedMask = "ReservedMask",
}

export interface PropertyInfo {
  name: string;
  declaringType?: Type;
  reflectedType?: Type;
  module: Module;
  customAttributes: CustomAttributeData[];
  isCollectible: boolean;
  /** @format int32 */
  metadataToken: number;
  memberType: MemberTypes;
  propertyType: Type;
  attributes: PropertyAttributes;
  isSpecialName: boolean;
  canRead: boolean;
  canWrite: boolean;
  getMethod?: MethodInfo;
  setMethod?: MethodInfo;
}

export interface QuickSearchResponse {
  phrase: string;
  /** @format int32 */
  totalHits: number;
  products: ProductItemResponse[];
}

export type RelativeUrl = string;

export interface RelewiseProductRecommendationRequest {
  /** @format int32 */
  amount?: number | null;
  type: RelewiseRecommendationType;
  fillIn?: boolean | null;
  includeVariants?: boolean | null;
  productFamilyId?: ProductFamilyId;
  productMasterId?: ProductMasterId;
  displayedAtLocation: string;
  categories: string[];
  subCategories: string[];
  collections: CollectionId[];
  predefinedProductFilters: FilterRequest[];
  selectedIds: string[];
  productLineIds: string[];
  catalogueIds: string[];
}

export enum RelewiseRecommendationType {
  PopularMostPurchased = "PopularMostPurchased",
  PopularMostViewed = "PopularMostViewed",
  RecentlyViewed = "RecentlyViewed",
  PurchasedWith = "PurchasedWith",
  PurchasedWithBasket = "PurchasedWithBasket",
  ViewedAfterViewingProduct = "ViewedAfterViewingProduct",
  PopularBoughtNotBought = "PopularBoughtNotBought",
}

export interface RemoveBasketLineRequest {
  variantId?: ProductVariantId;
  productFamilyId?: ProductFamilyId;
  bundleId: ProductBundleId;
  shipToId: ShipToId;
  /** @format date */
  deliveryDate: string;
  requestedFilterTypes: BasketFilterType[];
  shipmentFilterType: BasketFilterType;
}

export interface RemoveFromFavouriteListRequest {
  productMasterId: ProductMasterId;
  isFreeAssortment: boolean;
  /** @format date */
  deliveryDate: string;
}

export interface RemoveLinesForBundleRequest {
  bundleId: ProductBundleId;
  shipToId?: ShipToId;
  /** @format date */
  deliveryDate?: string | null;
  requestedFilterTypes: BasketFilterType[];
  shipmentFilterType: BasketFilterType;
}

export interface RemoveLinesForFamilyRequest {
  familyId: ProductFamilyId;
  shipToId?: ShipToId;
  /** @format date */
  deliveryDate?: string | null;
  requestedFilterTypes: BasketFilterType[];
  shipmentFilterType: BasketFilterType;
}

export interface RemoveLinesForMasterRequest {
  bundleId: ProductBundleId;
  masterId: ProductMasterId;
  shipToId?: ShipToId;
  /** @format date */
  deliveryDate?: string | null;
  requestedFilterTypes: BasketFilterType[];
  shipmentFilterType: BasketFilterType;
}

export interface ResourceLocation {
  externalRoute: RelativeUrl;
  area: Area;
  productFamilyId?: ProductFamilyId;
  productCategoryId?: ProductCategoryId;
  pageType?: PageType;
}

export enum Role {
  Customer = "Customer",
  SalesPerson = "SalesPerson",
  Admin = "Admin",
}

export interface RouteResponse {
  route: ResourceLocation;
  /** @format int32 */
  segmentationId: number;
  isNotFound: boolean;
  isInternalServerError: boolean;
  userMessage?: string | null;
}

export interface RuntimeFieldHandle {
  value: IntPtr;
}

export interface RuntimeMethodHandle {
  value: IntPtr;
}

export interface RuntimeTypeHandle {
  value: IntPtr;
}

export interface ScannerResponse {
  redirectUrl: RelativeUrl;
}

export interface SearchRequest {
  segmentationId: SegmentationId;
  phrase?: string | null;
  /** @format int32 */
  minPrice?: number | null;
  /** @format int32 */
  maxPrice?: number | null;
  page?: PageNumber;
  pageSize?: PageSize;
  sortBy?: string | null;
  sortDirection?: string | null;
  searchType: SearchType;
  productCategoryId?: ProductCategoryId;
  filters?: FilterRequest[] | null;
}

export enum SearchType {
  Family = "Family",
  Master = "Master",
}

export enum SecurityRuleSet {
  None = "None",
  Level1 = "Level1",
  Level2 = "Level2",
}

/** @format int32 */
export type SegmentationId = number;

export interface SegmentationResponse {
  /** @format int32 */
  presentationBrandId?: number | null;
  /** @format int32 */
  languageId: number;
  cultureCode: string;
  name: string;
  isCurrentSegmentation: boolean;
}

export interface SetBasketResponse {
  basketResponse: BasketDetailsResponse;
  pdpBasketContent?: BasketContentsResponse;
  switchSegmentTo?: SegmentationId;
}

export interface SetBrandRequest {
  /** @format int32 */
  presentationBrandId: number;
  /**
   * Nullable because the layout may not have loaded
   * by the time this object is created in the frontend.
   * @format int32
   */
  languageId?: number | null;
  returnUrl?: string | null;
}

export interface SetLanguageRequest {
  /** @format int32 */
  languageId: number;
  currentPath: string;
}

export interface SetPresentationBrandRequest {
  presentationBrandId: PresentationBrandId;
}

export interface ShipToDetails {
  id: ShipToId;
  name: string;
  address: string;
}

export type ShipToId = string;

export interface ShipToInfoResponse {
  id: string;
  name: string;
  postalCode: string;
  city: string;
  streetAndNumber: string;
  /** @format int32 */
  positionNumber?: number | null;
}

export interface ShipToListResponse {
  shipTos: ShipToInfoResponse[];
  vmiEnabled: boolean;
  cmiEnabled: boolean;
  pagingInformation: PagingInformationResponse;
}

export interface ShipToSearchRequest {
  phrase?: string | null;
  selectedShipToIds?: ShipToId[] | null;
  /** @format int32 */
  pageSize: number;
  /** @format int32 */
  page: number;
}

export interface ShipToTrackingModelResponse {
  shipToId: ShipToId;
  shipToName: string;
  currencyCode: CurrencyCode;
  businessTradingPartnerName?: string | null;
  businessTradingPartnerId?: BusinessTradingPartnerId;
  customerGroups: CustomerGroupTrackingModelResponse[];
}

export interface SignInRequest {
  segmentationId: SegmentationId;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * @format password
   * @minLength 1
   */
  password: string;
  rememberMe: boolean;
}

export enum SortDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export interface SortingInformationResponse1 {
  sortBy: ProductSort;
  sortDirection: SortDirection;
  displayName: string;
}

export interface SortingResponseOrderHistorySort {
  sortBy: OrderHistorySort;
  sortDirection: SortDirection;
  displayName: string;
}

export interface SortingResponseOrderInvoiceSort {
  sortBy: OrderInvoiceSort;
  sortDirection: SortDirection;
  displayName: string;
}

export interface SplashResponse {
  text?: string | null;
  type?: SplashType;
}

export enum SplashType {
  Discount = "Discount",
  DeliveryMonth = "DeliveryMonth",
  NeverOutOfStock = "NeverOutOfStock",
  NeverOutOfStockType = "NeverOutOfStockType",
  FashionForward = "FashionForward",
}

export interface StaticLinkModel {
  pageType: PageType;
  url: RelativeUrl;
}

export interface StatusResponse {
  isOnboardingCompleted: boolean;
}

export interface StructLayoutAttribute {
  typeId: any;
  value: LayoutKind;
}

export interface SubBrandResponse {
  /** @format int32 */
  presentationBrandId: number;
  brandId: string;
  name: string;
}

export interface Total {
  totalQuantity: LocalizedBundledQuantity;
  totalPrice: LocalizedPriceWithSymbol;
}

export interface TrackAndTraceResponse {
  code?: string | null;
  url?: AbsoluteUrl;
}

export interface TranslationsWebsite {
  email: TranslationsWebsiteEmail;
  splash: TranslationsWebsiteSplash;
  popups: TranslationsWebsitePopups;
  accountSelector: TranslationsWebsiteAccountSelector;
  preLogin: TranslationsWebsitePreLogin;
  mobileMenu: TranslationsWebsiteMobileMenu;
  scannerApp: TranslationsWebsiteScannerApp;
  header: TranslationsWebsiteHeader;
  footer: TranslationsWebsiteFooter;
  miniBasket: TranslationsWebsiteMiniBasket;
  myOrders: TranslationsWebsiteMyOrders;
  myInvoices: TranslationsWebsiteMyInvoices;
  myProfile: TranslationsWebsiteMyProfile;
  shared: TranslationsWebsiteShared;
  auth: TranslationsWebsiteAuth;
  pageTitles: TranslationsWebsitePageTitles;
  productList: TranslationsWebsiteProductList;
  productCard: TranslationsWebsiteProductCard;
  productDetails: TranslationsWebsiteProductDetails;
  preBasket: TranslationsWebsitePreBasket;
  basket: TranslationsWebsiteBasket;
  openAccounts: TranslationsWebsiteOpenAccounts;
  openBaskets: TranslationsWebsiteOpenBaskets;
  vmi: TranslationsWebsiteVmi;
  brand: TranslationsWebsiteBrand;
  brandSelector: TranslationsWebsiteBrandSelector;
  brandSelectorMenu: TranslationsWebsiteBrandSelectorMenu;
  favouriteList: TranslationsWebsiteFavouriteList;
  pDFGeneration: TranslationsWebsitePDFGeneration;
  openFavouriteLists: TranslationsWebsiteOpenFavouriteLists;
  order: TranslationsWebsiteOrder;
}

export interface TranslationsWebsiteAccountSelector {
  chooseAccountTitle: string;
  continueWithAccounts: string;
  selectAllLoaded: string;
  noAccountsForSearch: string;
  noLinkedAccountsForSalesPerson: string;
  accountsInBasket: string;
  viewAccounts: string;
  errorFetchingSelectedAccounts: string;
  errorFetchingUsersShipTos: string;
  errorSearchingForUsersShipTos: string;
  showingAccountsError: string;
  noAccessToPresentationBrand: string;
  selectAllAccountsWarning: string;
  selectAllAccountsWarningDescription: string;
  editAccounts: TranslationsWebsiteAccountSelectorEditAccounts;
}

export interface TranslationsWebsiteAccountSelectorEditAccounts {
  salesRepsFilterName: string;
  customerGroupsFilterName: string;
  manageYourAccounts: string;
  saveMyAccounts: string;
  seeAllFilters: string;
  searchAccounts: string;
  allFilters: string;
  apply: string;
  resetFilters: string;
  loadAll: string;
  allLoaded: string;
  cancelLoadAll: string;
  noFilterForSearch: string;
  filterCustomerGroup: string;
  filterSalesPerson: string;
  removeAccounts: string;
  removeFromYourAccounts: string;
  addAccounts: string;
}

export interface TranslationsWebsiteAuth {
  impersonating: string;
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
  phone: string;
  address: string;
  zipcode: string;
  city: string;
  signin: TranslationsWebsiteAuthSignin;
  forgotPassword: TranslationsWebsiteAuthForgotPassword;
  register: TranslationsWebsiteAuthRegister;
}

export interface TranslationsWebsiteAuthForgotPassword {
  heading: string;
  text: string;
  submit: string;
  success: string;
}

export interface TranslationsWebsiteAuthRegister {
  heading: string;
  text: string;
  submit: string;
  success: string;
}

export interface TranslationsWebsiteAuthSignin {
  heading: string;
  text: string;
  forgotPassword: string;
  rememberMe: string;
  login: string;
  logout: string;
  newCustomer: string;
  register: string;
}

export interface TranslationsWebsiteBasket {
  basketAvailabilitiesInfo: string;
  basketAvailabilitiesAmountSingular: string;
  basketAvailabilitiesAmountPlural: string;
  basketNotFoundError: string;
  copyBasketNoResultsFor: string;
  copyBasketSuccess: string;
  copyBasketChooseAccount: string;
  copyBasketChooseAccounts: string;
  copyBasketCopyProductsTo: string;
  copyBasketCopyProductsFrom: string;
  copyBasketSameAccountsInstructions: string;
  copyBasketNewAccountsLabel: string;
  copyBasketSameAccountsLabel: string;
  copyBasketHeader: string;
  quantity: string;
  customerOrderReferenceLengthError: string;
  customerOrderReferenceSpaceError: string;
  includingDiscount: string;
  stockTitle: string;
  placingOrder: string;
  bePatient: string;
  markup: string;
  rrp: string;
  wsp: string;
  customerRef: string;
  accountName: string;
  totalQuantity: string;
  productBrandDoesNotMatchBasketBrand: string;
  productIdMissing: string;
  basketOverview: string;
  filterByShippingPeriod: string;
  shipping: string;
  totalValue: string;
  color: string;
  navigateToFrontpage: string;
  orderCompleted: string;
  total: string;
  checkoutFailed: string;
  checkoutDeliveryChanged: string;
  checkoutDeliveryLineChanged: string;
  orderSummary: string;
  customerOrderReference: string;
  basketId: string;
  accountId: string;
  totalAmount: string;
  totalCost: string;
  complete: string;
  confirmation: string;
  basketEmpty: string;
  newBasketName: string;
  basketEmptyMessage: string;
  continueShoppingButton: string;
  checkoutButton: string;
  optionNotAvailable: string;
  quantityUnavailableError: string;
  noBasketSelected: string;
  basketDoesNotExist: string;
  cannotSelectBasket: string;
  cannotDeleteSelectedBasket: string;
  invalidModel: string;
  invalidShipToIds: string;
  invalidBasketId: string;
  basketShipTosNotUpdated: string;
  itemRemovedFromBasket: string;
  itemUpdatedInBasket: string;
  allDeliveryDatesFilter: string;
  basketHeaderTitle: string;
  unitsLeft: string;
  unitsLeftSingular: string;
  basketHeaderTotal: string;
  basketHeaderValue: string;
  totalInBasketProductTableHeader: string;
  valueInBasketMobileProductTableHeader: string;
  valueInBasketProductTableHeader: string;
  quantityInBasketProductTableFooter: string;
  valueInBasketProductTableFooter: string;
  basketModalDeleteStyleHeader: string;
  basketModalDeleteStyleOptionHeader: string;
  basketModalDeleteStyleBody: string;
  basketModalDeleteStyleOptionBody: string;
  variantQuantityUpdateFailed: string;
  boxesLabel: string;
  freeAssortmentsLabel: string;
  segmentationDoesNotMatch: string;
  containsInaccessibleProducts: string;
  disabledCellInBasket: string;
  viewInfo: string;
  size: string;
  length: string;
  chooseQuantity: string;
  chooseLength: string;
  boxAmountSingular: string;
  boxAmountPlural: string;
  remainingBoxesSingular: string;
  remainingBoxesPlural: string;
  noRemainingBoxes: string;
  stockAvailability: string;
  emptyBasket: string;
  noAccountOptions: string;
  noProductOptions: string;
  confirmationDrawer: TranslationsWebsiteBasketConfirmationDrawer;
  titleBar: TranslationsWebsiteBasketTitleBar;
  summary: TranslationsWebsiteBasketSummary;
  basketNotificationModal: TranslationsWebsiteBasketBasketNotificationModal;
  pageMetaData: TranslationsWebsiteBasketPageMetaData;
  header: TranslationsWebsiteBasketHeader;
}

export interface TranslationsWebsiteBasketBasketNotificationModal {
  title: string;
  notEnoughStock: string;
}

export interface TranslationsWebsiteBasketConfirmationDrawer {
  title: string;
  description: string;
  confirmButtonLabel: string;
  tryAgainButtonLabel: string;
  noPermission: string;
}

export interface TranslationsWebsiteBasketHeader {
  pdpTitle: string;
}

export interface TranslationsWebsiteBasketPageMetaData {
  title: string;
  description: string;
  keywords: string;
}

export interface TranslationsWebsiteBasketSummary {
  title: string;
  subtotal: string;
  total: string;
}

export interface TranslationsWebsiteBasketTitleBar {
  product: string;
  unitPrice: string;
  quantity: string;
  lineTotal: string;
}

export interface TranslationsWebsiteBrand {
  noAccessToBrand: string;
}

export interface TranslationsWebsiteBrandSelector {
  chooseBrand: string;
  chooseSubBrand: string;
  manageAccounts: string;
  enterBrand: string;
  enterSubBrand: string;
}

export interface TranslationsWebsiteBrandSelectorMenu {
  loginTitle: string;
  title: string;
  baskets: string;
}

export type TranslationsWebsiteEmail = object;

export interface TranslationsWebsiteFavouriteList {
  editButton: string;
  changeList: string;
  removeProductToFavouriteList: string;
  addProductToFavouriteList: string;
  noProductsTitle: string;
  noProductsDesc: string;
  favouriteListId: string;
  basketNotFound: string;
  changeFavourites: string;
  title: string;
  cannotDeleteActiveList: string;
  cannotDeleteLists: string;
  invalidRequest: string;
  addToListUnsuccessful: string;
  removeFromListUnsuccessful: string;
  setNameSpaceError: string;
  setNameUnsuccessful: string;
  setFavouriteListUnsuccessfulInvalidBrand: string;
  invalidFavouriteListId: string;
  noAccessToList: string;
  setActiveUnsuccessful: string;
  favouritelistTotalUnsuccessful: string;
  copyFavouriteListUnsuccessful: string;
  copyFavouriteListSuccess: string;
  viewFavouriteList: string;
  saveAsMyOwn: string;
  unGrouped: string;
  uncategorized: string;
  unknown: string;
  pageMetaData: TranslationsWebsiteFavouriteListPageMetaData;
}

export interface TranslationsWebsiteFavouriteListPageMetaData {
  title: string;
  description: string;
  keywords: string;
}

export interface TranslationsWebsiteFooter {
  socialTitle: string;
  copyright: string;
  email: string;
  companyName: string;
  streetName: string;
  zipAndCity: string;
  phoneNumber: string;
  contactMail: string;
}

export interface TranslationsWebsiteHeader {
  languageDrawerHeaderTitle: string;
  headerMenuMoreButtonText: string;
}

export interface TranslationsWebsiteMiniBasket {
  productsInCart: string;
  subtotal: string;
  discount: string;
  shipping: string;
  vat: string;
  totalPrice: string;
  goToBasket: string;
}

export interface TranslationsWebsiteMobileMenu {
  productsTitle: string;
  openFavourites: string;
}

export interface TranslationsWebsiteMyInvoices {
  pDFExportLabel: string;
  excelExportLabel: string;
  myInvoicesTitle: string;
  invoiceNumber: string;
  invoiceDate: string;
  orderNumber: string;
  account: string;
  quantity: string;
  value: string;
  track: string;
  invoice: string;
  viewInvoice: string;
  valueInfoText: string;
  exportAll: string;
  totalQuantity: string;
  totalValue: string;
  searchPlaceholder: string;
  style: string;
  price: string;
  goToOrder: string;
  dateRange: string;
  noResultsTitle: string;
  noResultsDescription: string;
  clearFilters: string;
  clearSearch: string;
  fetchingInvoicesTitle: string;
}

export interface TranslationsWebsiteMyOrders {
  showOrdersButtonLabel: string;
  noResultsFor: string;
  noResultsForDescription: string;
  myOrdersTitle: string;
  accounts: string;
  account: string;
  totalItems: string;
  totalQuantity: string;
  totalValue: string;
  all: string;
  delivered: string;
  inbound: string;
  multipleCurrencies: string;
  noOrdersFound: string;
  noOrdersFoundSuggestion: string;
  noAccountsSelectedSuggestion: string;
  showingAccountsErrorSuggestion: string;
  clearFilters: string;
  clearSearch: string;
  goToProduct: string;
  xInboundToYou: string;
  xInbound: string;
  orderHistoryDetailFor: string;
  viewOrderHistory: string;
  defaultGroupingDisplayName: string;
  orderDetail: TranslationsWebsiteMyOrdersOrderDetail;
  orderHistoryFacets: TranslationsWebsiteMyOrdersOrderHistoryFacets;
  orderHistoryLine: TranslationsWebsiteMyOrdersOrderHistoryLine;
  sorting: TranslationsWebsiteMyOrdersSorting;
  orderDetails: TranslationsWebsiteMyOrdersOrderDetails;
}

export interface TranslationsWebsiteMyOrdersOrderDetail {
  orderDetailTitle: string;
  orderNotFoundTitle: string;
  orderNotFoundDescription: string;
  returnToOrders: string;
  reBuyOrder: string;
}

export interface TranslationsWebsiteMyOrdersOrderDetails {
  styleNumber: string;
  styleNotFound: string;
  notAvailable: string;
  orderNumberShort: string;
  orderNumberFull: string;
  copyOrderNumber: string;
  copiedOrderNumber: string;
  clientReference: string;
  datePlaced: string;
  brand: string;
  basketId: string;
  estimatedShipping: string;
  warehouseEta: string;
  platform: string;
  quantity: string;
  quantityShortName: string;
  value: string;
  price: string;
  net: string;
  wholesalePrice: string;
  recommendedRetailPrice: string;
  markup: string;
  discount: string;
  delivered: string;
  orderStatus: string;
  sizeSplit: string;
  item: string;
  items: string;
  delivery: string;
}

export interface TranslationsWebsiteMyOrdersOrderHistoryFacets {
  gender: string;
  colour: string;
  orderType: string;
  category: string;
  subCategory: string;
  deliveryMonth: string;
  deliveryYear: string;
  noos: string;
  brand: string;
  subBrand: string;
  platform: string;
  basketCreatorName: string;
}

export interface TranslationsWebsiteMyOrdersOrderHistoryLine {
  trackOrder: string;
  viewOrder: string;
  viewInvoices: string;
  viewSizeSplit: string;
  viewOrdersButtonDisabled: string;
  trackOrdersButtonDisabled: string;
  trackingCodeButtonDisabled: string;
  viewInvoicesButtonDisabled: string;
  goToProduct: string;
  goToProductButtonDisabledTooltip: string;
  platform: TranslationsWebsiteMyOrdersOrderHistoryLinePlatform;
  status: TranslationsWebsiteMyOrdersOrderHistoryLineStatus;
}

export interface TranslationsWebsiteMyOrdersOrderHistoryLinePlatform {
  desktop: string;
  direct: string;
  digitalShowroom: string;
  autoReplenishment: string;
  ediOrder: string;
  showroom: string;
  more: string;
  cmi: string;
}

export interface TranslationsWebsiteMyOrdersOrderHistoryLineStatus {
  open: string;
  completed: string;
  allocated: string;
  shipped: string;
  backorder: string;
  printed: string;
  reserved: string;
}

export interface TranslationsWebsiteMyOrdersSorting {
  orderNumberAscending: string;
  orderNumberDescending: string;
  styleNumberAscending: string;
  styleNumberDescending: string;
  estimatedDeliveryDateAscending: string;
  estimatedDeliveryDateDescending: string;
  deliveryDateAscending: string;
  deliveryDateDescending: string;
  unitNetPriceAscending: string;
  unitNetPriceDescending: string;
  orderLineNetPriceAscending: string;
  orderLineNetPriceDescending: string;
  orderLineQuantityPiecesAscending: string;
  orderLineQuantityPiecesDescending: string;
  salesChannelNameAscending: string;
  salesChannelNameDescending: string;
  invoiceNumberAscending: string;
  invoiceNumberDescending: string;
  invoiceDateAscending: string;
  invoiceDateDescending: string;
}

export interface TranslationsWebsiteMyProfile {
  myProfileTitle: string;
  changePassword: string;
  preventDeviceZoom: string;
  preventDeviceZoomTooltip: string;
  hideWhsPrice: string;
  hideWhsPriceTooltip: string;
}

export interface TranslationsWebsiteOpenAccounts {
  title: string;
  manageBtn: string;
}

export interface TranslationsWebsiteOpenBaskets {
  orderConfirmationTitle: string;
  manageAccounts: string;
  title: string;
  active: string;
  basketUpdatedSuccess: string;
  namelessBasketUpdatedSuccess: string;
  openBasketsTitle: string;
  creatorButtonLabel: string;
  creatorTitle: string;
  searchPlaceholder: string;
  myOrders: string;
  noBasketsTitle: string;
  noBasketsDescription: string;
  fetchingBasketsTitle: string;
  fetchingBasketsDescription: string;
  someProductsUnavailable: string;
  allProductsUnavailable: string;
  create: TranslationsWebsiteOpenBasketsCreate;
  edit: TranslationsWebsiteOpenBasketsEdit;
  filters: TranslationsWebsiteOpenBasketsFilters;
  delete: TranslationsWebsiteOpenBasketsDelete;
}

export interface TranslationsWebsiteOpenBasketsCreate {
  title: string;
  reference: string;
  find: string;
  submit: string;
  success: string;
  error: string;
  new: string;
}

export interface TranslationsWebsiteOpenBasketsDelete {
  deleteToggle: string;
  deleteSubmit: string;
  confirmDeleteSelectedBasketsDescription: string;
  basketDeletedSuccessfully: string;
}

export interface TranslationsWebsiteOpenBasketsEdit {
  title: string;
  submit: string;
  success: string;
  editList: string;
  delete: string;
  deleteBasket: string;
  deleteBaskets: string;
}

export interface TranslationsWebsiteOpenBasketsFilters {
  allFilters: string;
  apply: string;
  resetFilters: string;
  noResultsFor: string;
  noFiltersForSearch: string;
}

export interface TranslationsWebsiteOpenFavouriteLists {
  successMessage: string;
  createList: string;
  favourites: string;
  title: string;
  favouriteListSelected: string;
  openLists: string;
  edit: TranslationsWebsiteOpenFavouriteListsEdit;
  delete: TranslationsWebsiteOpenFavouriteListsDelete;
}

export interface TranslationsWebsiteOpenFavouriteListsDelete {
  deleteSubmitSingular: string;
  deleteSubmitPlural: string;
  confirmDeleteSelectedFavListsDescription: string;
  favListsDeletedSuccessfully: string;
}

export interface TranslationsWebsiteOpenFavouriteListsEdit {
  title: string;
  updateFailed: string;
}

export interface TranslationsWebsiteOrder {
  referenceNumberTooLong: string;
  nameRequired: string;
  addressRequired: string;
  cityRequired: string;
  zipCodeRequired: string;
  emailRequired: string;
  emailInvalid: string;
  phoneNumberRequired: string;
  commentTooLong: string;
  createOrderFailed: string;
  basketNotFoundOrEmpty: string;
  validationFailed: string;
  userBasketValidationFailed: string;
  validation: TranslationsWebsiteOrderValidation;
  pageMetaData: TranslationsWebsiteOrderPageMetaData;
}

export interface TranslationsWebsiteOrderPageMetaData {
  title: string;
  description: string;
  keywords: string;
}

export type TranslationsWebsiteOrderValidation = object;

export interface TranslationsWebsitePDFGeneration {
  failedToGeneratePDF: string;
  pageNumber: string;
  printDate: string;
  pdfWithPicture: string;
  pdfInAList: string;
}

export interface TranslationsWebsitePageTitles {
  accountSelector: string;
  articleList: string;
  login: string;
  forgottenPassword: string;
  resetPassword: string;
  register: string;
  confirmEmail: string;
}

export interface TranslationsWebsitePopups {
  preBasketUnsavedChanges: TranslationsWebsitePopupsPreBasketUnsavedChanges;
}

export interface TranslationsWebsitePopupsPreBasketUnsavedChanges {
  title: string;
  description: string;
  discardChangesButtonLabel: string;
  saveChangesButtonLabel: string;
}

export interface TranslationsWebsitePreBasket {
  starterBoxInfoText: string;
  unitsAvailable: string;
  oneUnitAvailable: string;
  noUnitsAvailable: string;
  noProductsAvailable: string;
  loadingAvailableProducts: string;
  errorLoadingProducts: string;
  chooseDeliveryDate: string;
  totalValue: string;
  updateBasket: string;
  addToBasket: string;
  discardedPreBasket: string;
  itemsAddedToBasket: string;
  itemsUpdatedInBasket: string;
  orderHistoryDetailFor: string;
}

export interface TranslationsWebsitePreLogin {
  bestsellerLabel: string;
  loginButtonLabel: string;
  noAccessInformationalText: string;
}

export interface TranslationsWebsiteProductCard {
  styleId: string;
  styleNumber: string;
  inBasket: string;
  inbound: string;
  inboundForMultipleStores: string;
  delivered: string;
  deliveredForMultipleStores: string;
  price: TranslationsWebsiteProductCardPrice;
}

export interface TranslationsWebsiteProductCardPrice {
  markup: string;
  rrp: string;
  wsp: string;
}

export interface TranslationsWebsiteProductDetails {
  loadingQuantityOptions: string;
  loadingProduct: string;
  loadingProductFailed: string;
  chooseItems: string;
  chooseShippingPeriod: string;
  readMore: string;
  quantity: string;
  productDescription: string;
  salesDescription: string;
  qualityDescription: string;
  sustainabilityDescription: string;
  specifications: string;
  documents: string;
  relatedProducts: string;
  siblingsTitle: string;
  variantsAmount: string;
  variantsTitle: string;
  addedToBasket: string;
  gender: string;
  styleType: string;
  infoIcon: string;
  viewStyleInfo: string;
  styleInfo: string;
  manufacture: string;
  manufactureDetail: string;
  styleNumber: string;
  ean: string;
  boxItemsLabel: string;
  length: string;
  noAccountOptions: string;
  viewItem: string;
  copyStyleNumber: string;
  copiedStyleNumber: string;
  copiedEanNumber: string;
}

export interface TranslationsWebsiteProductList {
  changedFilterCriteriaMessage: string;
  categoryAll: string;
  categories: string;
  productListTitle: string;
  showing: string;
  products: string;
  searchInResults: string;
  searchIn: string;
  searchInResultsPlaceholder: string;
  showAll: string;
  showLess: string;
  showPagination: string;
  resultsFor: string;
  noResults: string;
  noResultsFor: string;
  noProducts: string;
  resultsFound: string;
  didYouMean: string;
  showMoreProductsButtonShow: string;
  showMoreProductsButtonMore: string;
  refineSelection: string;
  noProductsSelected: string;
  showAllSizes: string;
  searchSuggestionsHeadline: string;
  searchSuggestionsText1: string;
  searchSuggestionsText2: string;
  searchSuggestionsText3: string;
  fromText: string;
  borderStyleFilterTranslation: string;
  sortBy: string;
  groupBy: string;
  view: string;
  myNoos: string;
  availability: TranslationsWebsiteProductListAvailability;
  sorting: TranslationsWebsiteProductListSorting;
  filters: TranslationsWebsiteProductListFilters;
  pageMetaData: TranslationsWebsiteProductListPageMetaData;
  filterDisplayName: TranslationsWebsiteProductListFilterDisplayName;
}

export interface TranslationsWebsiteProductListAvailability {
  unknown: string;
  available: string;
  onlyFew: string;
  soldOut: string;
  inStoreOnly: string;
  externalWarehouse: string;
  soldOutOnWeb: string;
}

export interface TranslationsWebsiteProductListFilterDisplayName {
  brand: string;
  deliveryDate: string;
  fit: string;
  isFreeAssortment: string;
  length: string;
  mainColourName: string;
  manufactureMethod: string;
  fashionForward: string;
  necks: string;
  neverOutOfStock: string;
  neverOutOfStockType: string;
  price: string;
  size: string;
  sleeve: string;
  subBrand: string;
  targetGroups: string;
  presentationBrand: string;
  presentationSubBrand: string;
  nowDisplayName: string;
  hasDiscount: string;
  mainCatalogue: string;
  subCatalogue: string;
  hasLicense: string;
}

export interface TranslationsWebsiteProductListFilters {
  filtersAndSort: string;
  filtersTitle: string;
  filtersButton: string;
  allFilters: string;
  price: string;
  productGroup: string;
  brand: string;
  min: string;
  max: string;
  campaign: string;
  inCampaign: string;
  outlet: string;
  choose: string;
  resetFilters: string;
  showProductsButton: string;
  subPages: string;
}

export interface TranslationsWebsiteProductListPageMetaData {
  title: string;
  description: string;
  keywords: string;
}

export interface TranslationsWebsiteProductListSorting {
  sortingStr: string;
  relevance: string;
  rrpAscending: string;
  rrpDescending: string;
  markupAscending: string;
  markupDescending: string;
  nameAscending: string;
  nameDescending: string;
  wspAscending: string;
  wspDescending: string;
  latestAddedToFavouritesAscending: string;
  latestAddedToFavouritesDescending: string;
}

export interface TranslationsWebsiteScannerApp {
  scannerDescription: string;
  scanToSearch: string;
  item: string;
  basket: string;
  chooseBrand: string;
  chooseSubBrand: string;
  enterBrand: string;
  enterSubBrand: string;
  missingBrand: string;
  manageAccounts: string;
  alertScannerNoAccessCameraTitle: string;
  alertScannerNoAccessCameraMessage: string;
  alertNo: string;
  alertYesGoToSettings: string;
  invalidBarcode: string;
  scanErrorTitle: string;
  genericScanError: string;
  ok: string;
  dialogVersionTitle: string;
  dialogVersionMessage: string;
  offlineMessage: string;
  noPermissionsToProduct: string;
  productNotFound: string;
  productAlreadyOnList: string;
  tabTitle: TranslationsWebsiteScannerAppTabTitle;
}

export interface TranslationsWebsiteScannerAppTabTitle {
  shop: string;
  search: string;
  scan: string;
  favoriteList: string;
  profile: string;
}

export interface TranslationsWebsiteShared {
  shareSuccess: string;
  copy: string;
  share: string;
  edit: string;
  delete: string;
  expand: string;
  collapse: string;
  siteName: string;
  trueString: string;
  falseString: string;
  reset: string;
  close: string;
  back: string;
  save: string;
  cancel: string;
  confirm: string;
  continue: string;
  selectAll: string;
  deselectAll: string;
  previous: string;
  next: string;
  slide: string;
  becomes: string;
  menuLabel: string;
  searchPlaceholder: string;
  searchIconLabel: string;
  searchSubmit: string;
  miniBasketTitle: string;
  frontpage: string;
  showMore: string;
  showLess: string;
  layoutErrorMsg: string;
  genericErrorMsg: string;
  unsupportedVideo: string;
  unsupportedVideoLinkText: string;
  imageComingSoon: string;
  navigation: TranslationsWebsiteSharedNavigation;
  quicksearch: TranslationsWebsiteSharedQuicksearch;
  exports: TranslationsWebsiteSharedExports;
  pagination: TranslationsWebsiteSharedPagination;
  grouping: TranslationsWebsiteSharedGrouping;
}

export interface TranslationsWebsiteSharedExports {
  export: string;
  download: string;
  downloadAsPdf: string;
  downloadAsPdfList: string;
  startingPdfGeneration: string;
  pleaseKeepPageOpen: string;
  generatingPdfsTitle: string;
  pdfGenerationFailed: string;
  pdfGenerationSuccess: string;
  downloadAsExcel: string;
  downloadAsMediaContent: string;
  exportSuccess: string;
  exportFailed: string;
  exportTooltip: string;
  pdfList: TranslationsWebsiteSharedExportsPdfList;
}

export interface TranslationsWebsiteSharedExportsPdfList {
  pages: string;
  printDate: string;
  orderStatus: string;
  listTotal: string;
  dateTotal: string;
  categoryTotal: string;
  summaryTitle: string;
  month: string;
  year: string;
  totalQuantity: string;
  totalCost: string;
  totalReport: string;
  totalOrderStatus: string;
  orderNo: string;
  brand: string;
  styleId: string;
  styleName: string;
  assortment: string;
  eta: string;
  pcs: string;
  net: string;
  rrp: string;
  mu: string;
  totalColumn: string;
}

export interface TranslationsWebsiteSharedGrouping {
  groupBy: string;
  deliveryGrouping: string;
  categoryGrouping: string;
  orderLineGrouping: string;
  ungroupedGrouping: string;
}

export interface TranslationsWebsiteSharedNavigation {
  primary: string;
  footer: string;
  user: string;
  category: string;
  top: string;
  breadcrumbs: string;
  slider: string;
  allCategories: string;
  categoryAll: string;
}

export interface TranslationsWebsiteSharedPagination {
  youHaveViewed: string;
  of: string;
  products: string;
  showNext: string;
  showPrevious: string;
}

export interface TranslationsWebsiteSharedQuicksearch {
  close: string;
  showAll: string;
  suggestions: string;
  products: string;
}

export interface TranslationsWebsiteSplash {
  discountUpTo: string;
}

export interface TranslationsWebsiteVmi {
  title: string;
  description: string;
  selectAccount: string;
  selectBrand: string;
  download: string;
  downloadSuccess: string;
  downloadError: string;
}

export interface Type {
  name: string;
  customAttributes: CustomAttributeData[];
  isCollectible: boolean;
  /** @format int32 */
  metadataToken: number;
  isInterface: boolean;
  memberType: MemberTypes;
  namespace?: string | null;
  assemblyQualifiedName?: string | null;
  fullName?: string | null;
  assembly: Assembly;
  module: Module;
  isNested: boolean;
  declaringType?: Type;
  declaringMethod?: MethodBase;
  reflectedType?: Type;
  underlyingSystemType: Type;
  isTypeDefinition: boolean;
  isArray: boolean;
  isByRef: boolean;
  isPointer: boolean;
  isConstructedGenericType: boolean;
  isGenericParameter: boolean;
  isGenericTypeParameter: boolean;
  isGenericMethodParameter: boolean;
  isGenericType: boolean;
  isGenericTypeDefinition: boolean;
  isSZArray: boolean;
  isVariableBoundArray: boolean;
  isByRefLike: boolean;
  isFunctionPointer: boolean;
  isUnmanagedFunctionPointer: boolean;
  hasElementType: boolean;
  genericTypeArguments: Type[];
  /** @format int32 */
  genericParameterPosition: number;
  genericParameterAttributes: GenericParameterAttributes;
  attributes: TypeAttributes;
  isAbstract: boolean;
  isImport: boolean;
  isSealed: boolean;
  isSpecialName: boolean;
  isClass: boolean;
  isNestedAssembly: boolean;
  isNestedFamANDAssem: boolean;
  isNestedFamily: boolean;
  isNestedFamORAssem: boolean;
  isNestedPrivate: boolean;
  isNestedPublic: boolean;
  isNotPublic: boolean;
  isPublic: boolean;
  isAutoLayout: boolean;
  isExplicitLayout: boolean;
  isLayoutSequential: boolean;
  isAnsiClass: boolean;
  isAutoClass: boolean;
  isUnicodeClass: boolean;
  isCOMObject: boolean;
  isContextful: boolean;
  isEnum: boolean;
  isMarshalByRef: boolean;
  isPrimitive: boolean;
  isValueType: boolean;
  isSignatureType: boolean;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  structLayoutAttribute?: StructLayoutAttribute;
  typeInitializer?: ConstructorInfo;
  typeHandle: RuntimeTypeHandle;
  /** @format uuid */
  guid: string;
  baseType?: Type;
  /** @deprecated */
  isSerializable: boolean;
  containsGenericParameters: boolean;
  isVisible: boolean;
}

export enum TypeAttributes {
  NotPublic = "NotPublic",
  Public = "Public",
  NestedPublic = "NestedPublic",
  NestedPrivate = "NestedPrivate",
  NestedFamily = "NestedFamily",
  NestedAssembly = "NestedAssembly",
  NestedFamANDAssem = "NestedFamANDAssem",
  VisibilityMask = "VisibilityMask",
  SequentialLayout = "SequentialLayout",
  ExplicitLayout = "ExplicitLayout",
  LayoutMask = "LayoutMask",
  Interface = "Interface",
  Abstract = "Abstract",
  Sealed = "Sealed",
  SpecialName = "SpecialName",
  RTSpecialName = "RTSpecialName",
  Import = "Import",
  Serializable = "Serializable",
  WindowsRuntime = "WindowsRuntime",
  UnicodeClass = "UnicodeClass",
  AutoClass = "AutoClass",
  StringFormatMask = "StringFormatMask",
  HasSecurity = "HasSecurity",
  ReservedMask = "ReservedMask",
  BeforeFieldInit = "BeforeFieldInit",
  CustomFormatMask = "CustomFormatMask",
}

export interface TypeInfo {
  name: string;
  customAttributes: CustomAttributeData[];
  isCollectible: boolean;
  /** @format int32 */
  metadataToken: number;
  isInterface: boolean;
  memberType: MemberTypes;
  namespace?: string | null;
  assemblyQualifiedName?: string | null;
  fullName?: string | null;
  assembly: Assembly;
  module: Module;
  isNested: boolean;
  declaringType?: Type;
  declaringMethod?: MethodBase;
  reflectedType?: Type;
  underlyingSystemType: Type;
  isTypeDefinition: boolean;
  isArray: boolean;
  isByRef: boolean;
  isPointer: boolean;
  isConstructedGenericType: boolean;
  isGenericParameter: boolean;
  isGenericTypeParameter: boolean;
  isGenericMethodParameter: boolean;
  isGenericType: boolean;
  isGenericTypeDefinition: boolean;
  isSZArray: boolean;
  isVariableBoundArray: boolean;
  isByRefLike: boolean;
  isFunctionPointer: boolean;
  isUnmanagedFunctionPointer: boolean;
  hasElementType: boolean;
  genericTypeArguments: Type[];
  /** @format int32 */
  genericParameterPosition: number;
  genericParameterAttributes: GenericParameterAttributes;
  attributes: TypeAttributes;
  isAbstract: boolean;
  isImport: boolean;
  isSealed: boolean;
  isSpecialName: boolean;
  isClass: boolean;
  isNestedAssembly: boolean;
  isNestedFamANDAssem: boolean;
  isNestedFamily: boolean;
  isNestedFamORAssem: boolean;
  isNestedPrivate: boolean;
  isNestedPublic: boolean;
  isNotPublic: boolean;
  isPublic: boolean;
  isAutoLayout: boolean;
  isExplicitLayout: boolean;
  isLayoutSequential: boolean;
  isAnsiClass: boolean;
  isAutoClass: boolean;
  isUnicodeClass: boolean;
  isCOMObject: boolean;
  isContextful: boolean;
  isEnum: boolean;
  isMarshalByRef: boolean;
  isPrimitive: boolean;
  isValueType: boolean;
  isSignatureType: boolean;
  isSecurityCritical: boolean;
  isSecuritySafeCritical: boolean;
  isSecurityTransparent: boolean;
  structLayoutAttribute?: StructLayoutAttribute;
  typeInitializer?: ConstructorInfo;
  typeHandle: RuntimeTypeHandle;
  /** @format uuid */
  guid: string;
  baseType?: Type;
  /** @deprecated */
  isSerializable: boolean;
  containsGenericParameters: boolean;
  isVisible: boolean;
  genericTypeParameters: Type[];
  declaredConstructors: ConstructorInfo[];
  declaredEvents: EventInfo[];
  declaredFields: FieldInfo[];
  declaredMembers: MemberInfo[];
  declaredMethods: MethodInfo[];
  declaredNestedTypes: TypeInfo[];
  declaredProperties: PropertyInfo[];
  implementedInterfaces: Type[];
}

export interface UnauthorizedResult {
  /** @format int32 */
  statusCode: number;
}

export interface UpdateFavouriteListRequest {
  /** @minLength 1 */
  name: string;
}

export interface UpdateFavouriteShipToRequest {
  shipToIds: ShipToId[];
}

export interface UpdateNameAndShipTosForBasketRequest {
  orderReference?: string | null;
  /** @format date */
  deliveryDate?: string | null;
  shipToIds: string[];
}

export type Url = string;

export interface UserInfoResponse {
  isImpersonating: boolean;
  email: string;
  name: string;
  role?: Role;
  doNotExportOrdersToErp: boolean;
}

export interface ValidationResponse {
  type: ValidationResultType;
  message: string;
}

export enum ValidationResultType {
  Information = "Information",
  Error = "Error",
}

export interface VariantResponse {
  id: string;
  ean: string;
  size: string;
  length?: string | null;
}

export interface VersionsResponse {
  android: string;
  iOS: string;
}

export interface VmiBrandListRespose {
  brands: Brand[];
}

export type WebsiteUserId = string;
