import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult,
	useQueryClient,
	useInfiniteQuery,
	UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { FavouriteListExport as FavouriteListExportApi } from '../generated/FavouriteListExport';
import { FavouriteList as FavouriteListApi } from 'generated/FavouriteList';
import {
	AbsoluteUrlResponse,
	AddToFavouriteListRequest,
	Area,
	FavouriteListCopyResponse,
	FavouriteListCreateResponse,
	FavouriteListGroupingType,
	FavouriteListHashId,
	FavouriteListInitialResponse,
	FavouriteListPagedResponse,
	FavouriteListResponse,
	FilterRequest,
	NoPresentationBrandResult,
	PageNumber,
	PageType,
	PDFDownloadResponse,
	ProblemDetails,
	RemoveFromFavouriteListRequest,
	UpdateFavouriteListRequest,
} from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { isScannerApp, messageToApp } from 'helpers/app';
import { formatTranslation } from 'helpers/stringHelpers';
import { InitialState } from 'store/types';
import { useTranslationQuery } from './translations';

export const DEFAULT_PAGE_SIZE = 36;

export interface FavouriteListPageQuery {
	segmentationId?: number;
	pageSize?: number;
	sortBy?: string;
	filters?: FilterRequest[];
	sortDirection?: string;
	favouriteListHashId?: string;
	grouping?: FavouriteListGroupingType;
}

// GET favouritelist page
const fetchGroupItems = async (
	segmentationId: number,
	query: FavouriteListPageQuery,
	pageParam,
	groupingType: FavouriteListGroupingType,
	groupingKey: string,
): Promise<FavouriteListPagedResponse> => {
	const favouriteListApi = new FavouriteListApi({
		baseApiParams: { headers: setHeaders() },
	});

	const result = await favouriteListApi.favouritelistPageCreate({
		segmentationId: segmentationId,
		sortBy: query.sortBy,
		sortDirection: query.sortDirection,
		pageNumber: pageParam || 1,
		pageSize: query.pageSize || DEFAULT_PAGE_SIZE,
		filters: query.filters || [],
		favouriteListId: query.favouriteListHashId,
		groupingType: groupingType,
		groupingKey: groupingKey,
	});

	return result.data;
};

export const parseQueryParameters = (searchParams: URLSearchParams): FavouriteListPageQuery => {
	const filters: FilterRequest[] = [];
	const predefinedKeys = [
		'phrase',
		'page',
		'pageSize',
		'sortBy',
		'sortDirection',
		'minPrice',
		'maxPrice',
		'favouriteListId',
		'grouping',
	];
	searchParams.forEach(function (value, key) {
		if (predefinedKeys.some((k) => k === key)) return;
		filters.push({
			filter: key,
			value: value,
		});
	});

	return {
		pageSize: Number(searchParams.get('pageSize')) || undefined,
		sortBy: searchParams.get('sortBy') || undefined,
		filters: filters,
		sortDirection: searchParams.get('sortDirection') || undefined,
		favouriteListHashId: searchParams.get('favouriteListId') || undefined,
		grouping: (searchParams.get('grouping') as FavouriteListGroupingType) || undefined,
	};
};

export const useFavouriteListInitQuery = (): UseQueryResult<FavouriteListInitialResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const favouriteList = useTranslationQuery().data?.favouriteList;

	const { currentFilters } = useFavouriteFilters();
	const groupingType = currentFilters.grouping;
	const filters: FilterRequest[] = currentFilters.filters || [];

	return useQuery({
		queryKey: queryKeys.favouritelist.listInit(
			segmentationId,
			filters,
			groupingType,
			currentFilters.favouriteListHashId,
		).queryKey,
		queryFn: async (): Promise<FavouriteListInitialResponse> => {
			const favouriteListApi = new FavouriteListApi({
				baseApiParams: { headers: setHeaders() },
			});
			const response = await favouriteListApi.favouritelistIndexCreate({
				segmentationId,
				filters,
				groupingType,
				favouriteListId: currentFilters.favouriteListHashId,
			});

			if (!response.ok) {
				throw new Error(favouriteList?.setFavouriteListUnsuccessfulInvalidBrand);
			}

			return response.data;
		},
		keepPreviousData: true,
		cacheTime: 2000, // 2s
	});
};

export const useFavouriteGroupListInfiniteQuery = (
	groupingKey: string,
): UseInfiniteQueryResult<FavouriteListPagedResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	const { currentFilters } = useFavouriteFilters();
	const groupingType = currentFilters.grouping ?? FavouriteListGroupingType.Ungrouped;

	// We can't use a select here to map the response until v5 of react-query:
	// https://github.com/TanStack/query/issues/3065#issuecomment-1445431759
	return useInfiniteQuery({
		// currentPage should not be part of the key because react-query controls it internally
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.favouritelist.groupItems(segmentationId, currentFilters, groupingType, groupingKey)
			.queryKey,
		queryFn: ({ pageParam = 1 }) => {
			return fetchGroupItems(segmentationId, currentFilters, pageParam, groupingType, groupingKey);
		},
		getNextPageParam: (lastPage) => {
			const numberOfPages = Math.ceil((lastPage.pagingInformation.totalNumberOfItems || 1) / DEFAULT_PAGE_SIZE);
			const nextPage = lastPage.pagingInformation.currentPage + 1;
			return nextPage <= numberOfPages ? nextPage : undefined;
		},
		retry: false,
		refetchOnWindowFocus: isScannerApp ? 'always' : true,
		keepPreviousData: true,
	});
};

interface FavouriteListFilterResponse {
	setFavouriteSort: (sortBy?: string, sortDirection?: string) => void;
	setFavouriteGrouping: (grouping?: string) => void;
	setFavouriteListFilters: (toBeSet?: FilterRequest[], toBeRemoved?: FilterRequest[]) => void;
	currentFilters: FavouriteListPageQuery;
}

export const useFavouriteFilters = (): FavouriteListFilterResponse => {
	const [searchParams, setSearchParams] = useSearchParams();
	const currentFilters = parseQueryParameters(searchParams);

	const route = { area: Area.StaticPages, pageType: PageType.FavouriteList };

	const setFavouriteListFilters = (toBeSet?: FilterRequest[], toBeRemoved?: FilterRequest[]): void => {
		if ((!toBeSet || toBeSet?.length === 0) && (!toBeRemoved || toBeRemoved.length === 0)) return;

		setSearchParams(
			(params) => {
				if (toBeRemoved?.length) {
					toBeRemoved.forEach((filter) => {
						if (!filter.filter || !filter.value) return;
						params.delete(filter.filter, filter.value);
					});
				}
				if (toBeSet?.length) {
					toBeSet.forEach((filter) => {
						if (!filter.filter || !filter.value) return;
						params.append(filter.filter, filter.value);
					});
				}
				return params;
			},
			{ state: route, replace: true },
		);
	};

	const setFavouriteSort = (sortBy?: string, sortDirection?: string): void => {
		setSearchParams(
			(params) => {
				if (sortBy) params.set('sortBy', sortBy);
				else params.delete('sortBy');
				if (sortDirection) params.set('sortDirection', sortDirection);
				else params.delete('sortDirection');
				return params;
			},
			{ state: route, replace: true },
		);
	};

	const setFavouriteGrouping = (grouping?: string): void => {
		setSearchParams(
			(params) => {
				if (grouping !== undefined && grouping !== '') params.set('grouping', grouping);
				else params.delete('grouping');
				return params;
			},
			{ state: route, replace: true },
		);
	};

	return {
		setFavouriteSort,
		setFavouriteListFilters,
		setFavouriteGrouping,
		currentFilters,
	};
};

export interface SetFavouriteListNameRequest {
	data: UpdateFavouriteListRequest;
	favouriteListHashId: FavouriteListHashId;
}

export const useSetFavouriteListNameMutation = (): UseMutationResult<
	HttpResponse<PageNumber | void | ProblemDetails>,
	HttpResponse<void | ProblemDetails>,
	SetFavouriteListNameRequest
> => {
	const { notificationActions } = useNotificationContext();
	const queryClient = useQueryClient();
	const favouriteList = useTranslationQuery().data?.favouriteList;

	return useMutation({
		mutationFn: async (variables) => {
			const favouriteListApi = new FavouriteListApi({ baseApiParams: { headers: setHeaders() } });

			return favouriteListApi.favouritelistSetnameCreate(variables.data, {
				favouriteListHashId: variables.favouriteListHashId,
			});
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries(queryKeys.favouritelist.listInit._def);
			await queryClient.invalidateQueries(queryKeys.favouritelist.groupItems._def);
			messageToApp({ type: 'reloadFavoriteList' });
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(favouriteList?.setNameUnsuccessful, {}),
			});
		},
	});
};

export const useFavouriteListsQuery = (): UseQueryResult<FavouriteListResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const favouriteList = useTranslationQuery().data?.favouriteList;

	return useQuery({
		queryKey: queryKeys.favouritelist.list(segmentationId).queryKey,
		queryFn: async (): Promise<FavouriteListResponse> => {
			const favouriteListApi = new FavouriteListApi({
				baseApiParams: { headers: setHeaders() },
			});
			const response = await favouriteListApi.favouritelistListList();

			if (!response.ok) {
				throw new Error(favouriteList?.setFavouriteListUnsuccessfulInvalidBrand);
			}

			return response.data;
		},
	});
};

export const useSetFavouriteListMutation = (): UseMutationResult<
	HttpResponse<AbsoluteUrlResponse, ProblemDetails | void>,
	HttpResponse<ProblemDetails | void>,
	FavouriteListHashId
> => {
	const { notificationActions } = useNotificationContext();

	const favouriteList = useTranslationQuery().data?.favouriteList;

	return useMutation({
		mutationFn: async (variables) => {
			const favouriteListApi = new FavouriteListApi({ baseApiParams: { headers: setHeaders() } });

			return favouriteListApi.favouritelistSetactiveCreate(variables);
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(favouriteList?.invalidFavouriteListId, {}),
			});
		},
	});
};

export const useSetFavouriteListDeleteMutation = (): UseMutationResult<
	HttpResponse<PageNumber | void, ProblemDetails | void>,
	HttpResponse<ProblemDetails | void>,
	FavouriteListHashId[]
> => {
	const { notificationActions } = useNotificationContext();

	const favouriteList = useTranslationQuery().data?.favouriteList;

	return useMutation({
		mutationFn: async (variables) => {
			const favouriteListApi = new FavouriteListApi({ baseApiParams: { headers: setHeaders() } });
			return favouriteListApi.favouritelistDeleteCreate(variables);
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(favouriteList?.cannotDeleteLists, {}),
			});
		},
	});
};

export const useFavouritelistTotalQuery = (): UseQueryResult<number> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const favouriteList = useTranslationQuery().data?.favouriteList;

	return useQuery({
		queryKey: queryKeys.favouritelist.total(segmentationId).queryKey,
		queryFn: async (): Promise<number> => {
			const favouriteListApi = new FavouriteListApi({
				baseApiParams: { headers: setHeaders() },
			});
			const response = await favouriteListApi.favouritelistMiniList({ segmentationId: segmentationId });

			if (!response.ok) {
				throw new Error(favouriteList?.addToListUnsuccessful);
			}

			return response.data;
		},
	});
};

export const useSetFavouriteMutation = (): UseMutationResult<
	HttpResponse<void>,
	HttpResponse<void>,
	AddToFavouriteListRequest
> => {
	const { notificationActions } = useNotificationContext();
	const favouriteList = useTranslationQuery().data?.favouriteList;

	return useMutation({
		mutationFn: async (variables) => {
			const favouriteListApi = new FavouriteListApi({ baseApiParams: { headers: setHeaders() } });

			return favouriteListApi.favouritelistAddCreate(variables);
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(favouriteList?.addToListUnsuccessful, {}),
			});
		},
	});
};

export const useFavouriteListCreate = (): UseMutationResult<
	HttpResponse<FavouriteListCreateResponse, void | ProblemDetails | NoPresentationBrandResult>
> => {
	const { notificationActions } = useNotificationContext();

	const favouriteList = useTranslationQuery().data?.favouriteList;

	return useMutation({
		mutationFn: async () => {
			const favouriteListApi = new FavouriteListApi({ baseApiParams: { headers: setHeaders() } });

			return favouriteListApi.favouritelistCreateCreate();
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(favouriteList?.addToListUnsuccessful, {}),
			});
		},
	});
};

export const useRemoveFavouriteMutation = (): UseMutationResult<
	HttpResponse<void>,
	HttpResponse<void>,
	RemoveFromFavouriteListRequest
> => {
	const { notificationActions } = useNotificationContext();
	const favouriteList = useTranslationQuery().data?.favouriteList;

	return useMutation({
		mutationFn: async (variables) => {
			const favouriteListApi = new FavouriteListApi({ baseApiParams: { headers: setHeaders() } });

			return favouriteListApi.favouritelistRemoveCreate(variables);
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(favouriteList?.removeFromListUnsuccessful, {}),
			});
		},
	});
};

export const useFavouriteListPdfDataMutation = (): UseMutationResult<
	HttpResponse<PDFDownloadResponse, void | ProblemDetails>,
	HttpResponse<void>
> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	const { currentFilters } = useFavouriteFilters();
	const query = {
		segmentationId,
		favouriteListId: currentFilters.favouriteListHashId,
		sortBy: currentFilters.sortBy,
		sortDirection: currentFilters.sortDirection,
		groupingType: currentFilters.grouping,
	};

	return useMutation({
		mutationFn: async () => {
			const favouriteListApi = new FavouriteListExportApi({ baseApiParams: { headers: setHeaders() } });

			return await favouriteListApi.favouritelistexportListaspdfCreate(query);
		},
	});
};

export const useFavouriteListCopy = (): UseMutationResult<
	HttpResponse<FavouriteListCopyResponse>,
	HttpResponse<ProblemDetails | void>,
	FavouriteListHashId
> => {
	const { notificationActions } = useNotificationContext();
	const favouriteList = useTranslationQuery().data?.favouriteList;

	return useMutation({
		mutationFn: async (variables) => {
			const favoruiteListApi = new FavouriteListApi({ baseApiParams: { headers: setHeaders() } });

			return favoruiteListApi.favouritelistCopyCreate(variables);
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(favouriteList?.copyFavouriteListUnsuccessful, {}),
			});
		},
	});
};

export const useFavouriteListExcelMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void>
> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { currentFilters } = useFavouriteFilters();

	const query = {
		segmentationId,
		favouriteListId: currentFilters.favouriteListHashId,
		sortBy: currentFilters.sortBy,
		sortDirection: currentFilters.sortDirection,
	};

	return useMutation({
		mutationFn: async () => {
			const favouriteListApi = new FavouriteListExportApi({ baseApiParams: { headers: setHeaders() } });

			return await favouriteListApi.favouritelistexportExportasexcelCreate(query, { format: 'blob' });
		},
	});
};

export const useFavouriteListMediaContentMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void>
> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	const { currentFilters } = useFavouriteFilters();
	const query = {
		segmentationId,
		favouriteListId: currentFilters.favouriteListHashId,
		sortBy: currentFilters.sortBy,
		sortDirection: currentFilters.sortDirection,
	};

	return useMutation({
		mutationFn: async () => {
			const favouriteListApi = new FavouriteListExportApi({ baseApiParams: { headers: setHeaders() } });

			return await favouriteListApi.favouritelistexportExportasmediacontentCreate(query, {
				format: 'blob',
			});
		},
	});
};
