import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import { QueryClient } from '@tanstack/react-query';
import {
	AudienceTargetingResponse,
	FavouriteListGroupingType,
	FavouriteListHashId,
	FilterRequest,
	ImageLookupRequestItem,
	OrderHistoryGroupingRequest,
	OrderHistoryLineSearchRequest,
	OrderHistorySearchGroup,
	OrderInvoiceSearchRequest,
	PresentationBrandId,
	SegmentationId,
	ShipToId,
	ShipToSearchRequest,
} from 'generated/data-contracts';
import { getCookie } from 'helpers/cookies';
import { generateUUID } from 'helpers/generateUUID';
import { BasketFilters } from './basket';
import { FavouriteListPageQuery } from './favouriteList';
import { ProductBundleQuery } from './product';
import { RelewiseProductRecommendationQuery } from './recommendations';
import { ProductListPageQuery } from './search';

const DEFAULT_TIME = 15 * 60 * 1000;
let tabId = '';

const getTabId = (): string => {
	let sessionTabId = sessionStorage.getItem('tabId');

	if (sessionTabId) {
		return sessionTabId;
	}

	sessionTabId = generateUUID();
	sessionStorage.setItem('tabId', sessionTabId);

	return sessionTabId;
};

window.onfocus = (): void => {
	localStorage.setItem('lastTabId', tabId);
};

export const setHeaders = (cookies?: string): HeadersInit | undefined => {
	const headers: Record<string, string> = {};

	if (cookies) {
		headers.Cookie = cookies;

		const token = getCookie('CSRF-TOKEN', cookies) || '';
		headers.__RequestVerificationToken = token;
	} else if (document?.cookie) {
		const token = getCookie('CSRF-TOKEN', document.cookie) || '';
		headers.__RequestVerificationToken = token;
	}

	if (!tabId) {
		tabId = getTabId();

		const lastTabId = localStorage.getItem('lastTabId'); // Note if this is older than 24h then do we not want to use it.

		if (lastTabId) headers.lastTabId = lastTabId;
	}

	headers.tabId = tabId;

	return headers;
};

export const queryKeys = createQueryKeyStore({
	brand: {
		all: (segmentationId: SegmentationId) => [segmentationId],
		detail: (segmentationId: SegmentationId, id: PresentationBrandId) => [segmentationId, id],
	},
	product: {
		fetch: (id: string) => [id],
		list: (segmentationId: number, query: ProductListPageQuery) => [segmentationId, query],
		bundleQuery: (segmentationId: number, query: ProductBundleQuery) => [segmentationId, query],
		quickSearch: (segmentationId: number, phrase: string) => [segmentationId, phrase],
	},
	productBasket: {
		current: (productId: string, deliveryDate?: string, extraGrouping?: string) => [
			productId,
			deliveryDate,
			extraGrouping,
		],
	},
	shipTo: {
		search: (segmentationId: SegmentationId, page: number, phrase?: string, selectedIds?: string[]) => [
			segmentationId,
			page,
			phrase,
			selectedIds,
		],
		listselected: (segmentationId: SegmentationId) => [segmentationId],
		searchBasedOnBasketItems: (key: string, query: ShipToSearchRequest) => [key, query],
		searchFavourite: (
			segmentationId: SegmentationId,
			phrase?: string,
			selectedCustomerGroupIds?: string[],
			selectedSalesRepIds?: string[],
		) => [segmentationId, phrase, selectedCustomerGroupIds, selectedSalesRepIds],
		searchNonFavourite: (
			segmentationId: SegmentationId,
			pageSize?: number,
			phrase?: string,
			selectedCustomerGroupIds?: string[],
			selectedSalesRepIds?: string[],
		) => [segmentationId, pageSize, phrase, selectedCustomerGroupIds, selectedSalesRepIds],
		favouriteListSelected: (segmentationId: SegmentationId) => [segmentationId],
		listVmiShipTos: (segmentationId: SegmentationId) => [segmentationId],
		listVmiShipToBrands: (segmentationId: SegmentationId, shipToId?: ShipToId) => [segmentationId, shipToId],
		customerGroupFilter: (segmentationId: SegmentationId, phrase?: string) => [segmentationId, phrase],
		salesRepFilter: (segmentationId: SegmentationId, phrase?: string) => [segmentationId, phrase],
	},
	favouritelist: {
		total: (segmentationId: SegmentationId) => [segmentationId],
		list: (segmentationId: SegmentationId) => [segmentationId],
		listInit: (
			segmentationId: SegmentationId,
			filters: FilterRequest[],
			groupingType?: FavouriteListGroupingType,
			favouriteListId?: FavouriteListHashId,
		) => [segmentationId, filters, groupingType, favouriteListId],
		groupItems: (
			segmentationId: SegmentationId,
			currentFilters: FavouriteListPageQuery,
			groupingType: FavouriteListGroupingType,
			groupingKey: string,
		) => [segmentationId, currentFilters, groupingType, groupingKey],
	},
	settings: {
		all: (segmentationId: SegmentationId) => [segmentationId],
	},
	image: {
		lookup: (images: ImageLookupRequestItem[]) => [images],
	},
	layoutList: {
		fetch: (segmentationId: SegmentationId) => [segmentationId],
	},
	cms: {
		page: (url: string, modelName: string, audience?: AudienceTargetingResponse) => [
			modelName,
			url,
			JSON.stringify(audience),
		],
		menu: (modelName: string, audience?: AudienceTargetingResponse) => [modelName, JSON.stringify(audience)],
	},
	orderHistory: {
		list: (segmentationId: SegmentationId, query?: OrderHistoryLineSearchRequest) => [segmentationId, query],
		detail: (segmentationId: SegmentationId, orderNumber?: string) => [segmentationId, orderNumber],
		detailLines: (segmentationId: SegmentationId, orderNumber?: string) => [segmentationId, orderNumber],
		groupingLines: (
			segmentationId: SegmentationId,
			shipTo: string,
			familyId: string,
			bundleId?: string,
			masterId?: string,
			status?: OrderHistorySearchGroup,
		) => [segmentationId, shipTo, familyId, bundleId, masterId, status],
		groupings: (segmentationId: SegmentationId, query?: OrderHistoryGroupingRequest) => [segmentationId, query],
		pdpGroupings: (segmentationId: SegmentationId, familyId: string, bundleId?: string, masterId?: string) => [
			segmentationId,
			familyId,
			bundleId,
			masterId,
		],
	},
	translations: {
		fetch: (segmentationId: SegmentationId) => [segmentationId],
	},
	tracking: {
		pageFetch: (segmentationId: SegmentationId) => [segmentationId],
		productFetch: (segmentationId: SegmentationId, masterId: string) => [segmentationId, masterId],
	},
	basket: {
		current: (segmentationId: SegmentationId, shipmentFilter?: string) => [segmentationId, shipmentFilter],
		list: (segmentationId: SegmentationId, filters?: BasketFilters) => [segmentationId, filters],
		quantity: (segmentationId: SegmentationId) => [segmentationId],
		miniQuantity: (segmentationId: SegmentationId) => [segmentationId],
		productCardQuantity: (segmentationId: SegmentationId) => [segmentationId],
	},
	newBasket: {
		current: (key: string, extraGrouping?: string, deliveryDate?: string) => [key, extraGrouping, deliveryDate],
	},
	recommendations: {
		relewise: (segmentationId: SegmentationId, query: RelewiseProductRecommendationQuery) => [
			segmentationId,
			query,
		],
	},
	invoices: {
		list: (segmentationId: SegmentationId, query: OrderInvoiceSearchRequest) => [segmentationId, query],
	},
});

export const setAllQueryDefaults = (queryClient: QueryClient): void => {
	queryClient.setQueryDefaults(queryKeys.brand._def, { staleTime: DEFAULT_TIME });
	queryClient.setQueryDefaults(queryKeys.product._def, { staleTime: DEFAULT_TIME });
	queryClient.setQueryDefaults(queryKeys.productBasket._def, { staleTime: DEFAULT_TIME });
	queryClient.setQueryDefaults(queryKeys.shipTo._def, { staleTime: DEFAULT_TIME });
	queryClient.setQueryDefaults(queryKeys.favouritelist._def, { staleTime: DEFAULT_TIME });
	queryClient.setQueryDefaults(queryKeys.image._def, { staleTime: Infinity });
	queryClient.setQueryDefaults(queryKeys.layoutList._def, { staleTime: Infinity });
	queryClient.setQueryDefaults(queryKeys.tracking._def, { staleTime: DEFAULT_TIME });
	queryClient.setQueryDefaults(queryKeys.translations._def, { staleTime: DEFAULT_TIME });
	queryClient.setQueryDefaults(queryKeys.basket._def, { staleTime: DEFAULT_TIME });
	queryClient.setQueryDefaults(queryKeys.newBasket._def, { staleTime: DEFAULT_TIME });
	queryClient.setQueryDefaults(queryKeys.invoices._def, { staleTime: DEFAULT_TIME });
	queryClient.setQueryDefaults(queryKeys.cms._def, { staleTime: DEFAULT_TIME });
};
